section.wizard-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

section.wizard {
    position: absolute;
    background-color: white;
    color: black;
    padding: 0 50px;
    top: 10vh;
    max-width: 80vw;
    max-height: 80vh;
    overflow: scroll;
    align-items: flex-start;
}

section.wizard * {
    margin: 20px auto;
    color: black;
}

section.wizard .btn {
    color: white;
}

.btn.btn-secondary {
    background-color: lightgray;
    border-color: lightgray;
    color: darkslategray !important;
}

.btn.btn-danger {
    background-color: red;
    border-color: red;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}

.grid-item {
    width: 45%;
    border: 1px solid steelblue;
    background-color: steelblue;
    color: white !important;
    padding: 10px 0;
    cursor: pointer;
}

.grid-item.selected {
    background-color: forestgreen;
    font-weight: bold;
    border-color: forestgreen;
}

ul.score-entry-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style: none;
    padding: 0;
}

li.score-entry-item {
    display: flex;
    margin: 0;
    position: relative;
}

span.selected-team-name {
    padding: 10px;
    cursor: pointer;
    margin: 0 auto;
}

section.wizard input[type="text"] {
    margin: 0 auto;
}

ul.game-teams-list {
    width: 100%;
    list-style: none;
    padding: 0;
}

ul.score-entry-team-list {
    position: absolute;
    top: 45px;
    left: 0px;
    right: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
    list-style: none;
    padding: 20px;
    margin-top: 0;
    font-size: 1rem;
    height: 200px;
    overflow: scroll;
    border: 1px solid black;
    z-index: 100;
}

li.score-entry-team-item {
    margin: 0;
    cursor: pointer;
}

li.score-entry-team-item:nth-child(even) {
    background-color: lightgray;
}

li.score-entry-team-item:hover {
    background-color: lightgreen;
}

li.score-entry-team-item.create-new-team {
    background-color: lightblue;
}

.create-new-team-form {
    position: absolute;
    border: 1px solid black;
    background-color: white;
    padding: 50px;
}

.create-new-team-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.9);
}

.close {
    position: absolute;
    top: -10px;
    right: 10px;
    color: white;
    background-color: red;
    padding: 0 8px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    section.wizard {
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        width: 100vw;
        overflow: scroll;
    }
    li.score-entry-item {
        flex-direction: row;
    }
}
