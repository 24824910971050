header.team-page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 100;
}

.team-page-header p {
    margin: 0;
    font-size: 0.75rem;
}

span.total-teams a {
    color: #666;
}

h1.team-name {
    font-size: 2rem;
}

p.current-rank {
    font-weight: bold;
    font-size: 2rem;
}

.team-page-season-header {
    margin-right: 20px;
}
.team-page-season-header.active-season-false {
    display: none;
}

.active-season-true p.team-page-season {
    font-size: 1.5rem;
    padding: 10px 0;
}

.active-season-true .team-page-season a {
    color: white;
    text-decoration: none;
}

.team-page-season-header.active-season-true {
    width: 70vw;
}

.team-page-season-header.active-season-true table {
    width: 100%;
    max-width: 100%;
    display: table;
}

.team-page-content {
    display: flex;
    flex-direction: row;
    padding: 20px;
    width: 100%;
    align-items: flex-start;
    background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.95),
            rgba(255, 255, 255, 0.95)
        ),
        url("/public/images/bgtrivia2.jpg");
    background-repeat: repeat;
    background-size: contain;
    flex-wrap: wrap;
}

.team-page-content-section {
    flex: 1;
    margin-right: 20px;
}

.team-page-content h2 {
    color: #666;
    text-align: left;
    font-size: 1.5rem;
    border-bottom: 1px solid #666;
}

.team-page-content canvas {
    margin: 0 auto;
    padding: 20px;
    background-color: white;
}

.team-page-stats {
    display: flex;
    flex-direction: column;
}
.team-page-stats * {
    color: black;
}

.team-page-header-rank {
    border: 3px solid white;
    padding: 20px;
    background-color: lightslategray;
    position: relative;
    /* I want to make a background gradient that looks like platinum */

    background: linear-gradient(
        45deg,
        rgb(171 20 151) 0%,
        rgb(11 212 246) 100%
    );

    margin-bottom: -5px;
}

.team-page-header-rank:hover a.next-team,
.team-page-header-rank:hover a.prev-team {
    display: block;
}

span.current-rank {
    font-size: 2rem;
    font-weight: 700;
}

a.next-team,
a.prev-team {
    font-size: 0rem;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 65%;
    display: none;
}

a.next-team {
    right: 0;
    left: 65%;
}

h1.team-name {
    text-align: left;
    margin: 0;
    display: flex;
    flex-direction: column;
}

span.team-name-team-text {
    text-transform: uppercase;
    font-style: italic;
    font-size: 2.5rem;
    margin-top: 0;
}

span.team-name-team-text {
}

span.total-teams {
    font-size: 0.7rem;
    font-weight: 100;
    margin-top: 20px;
}
/* 
table tr:nth-child(even) td {
    color: black;
} */
li.team-score-page-score {
    position: relative;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    width: 45%;
    margin: 5px 2%;
    padding: 5px;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
}

li.team-score-page-score:hover {
    background-color: #f0f0f0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

ul.teams-page-scores-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

span.score-score {
    font-size: 3rem;
    font-weight: 700;
    color: #666;
    border-right: 1px solid #666;
    padding-right: 10px;
}

span.score-date-venue-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

span.score-venue {
    font-size: 1.25rem;
    font-weight: 700;
    color: #666;
}

span.score-date {
    font-size: 0.8rem;
    text-align: center;
    color: #333;
}
li.team-score-page-score.worst {
    background-color: lightpink;
}

li.team-score-page-score.best {
    background-color: lightgreen;
}

li.team-score-page-score.rank-1 {
    background-color: gold;
}

li.team-score-page-score.rank-2 {
    background-color: silver;
}

li.team-score-page-score.rank-3 {
    background-color: #cd7f32;
}
li.team-score-page-score.rank-3 * {
    color: #eee;
}

li.team-score-page-score.rank-3,
li.team-score-page-score.rank-2,
li.team-score-page-score.rank-1 {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
        100%;
    background-repeat: no-repeat;
    animation: shimmer 1.5s 2 alternate;
}

a.prev-team-side,
a.next-team-side {
    position: fixed;
    top: 45vh;
    font-size: 1rem;
    left: 2vw;
    background: rgba(0, 0, 255, 0.5);
    padding: 5px 15px;
}

a.next-team-side {
    left: unset;
    right: 2vw;
}
.team-page-stats-badges.team-page-content-section {
    min-width: 100%;
}

ul.badge-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    perspective: 1000px;
    margin: 0;
    display: none;
}

li.badge {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.8s;
    width: 30px;
    height: 30px;
    margin: 5px 5px -24px;
    padding: 0;
}

li.badge:hover {
    transform: rotateY(180deg);
}

.team-name li.badge:hover {
    transform: none;
}

.badge-front,
.badge-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
}

.badge-image {
    max-width: 100%;
}

.badge-front {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    justify-content: flex-end;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
        100%;
    background-repeat: no-repeat;
    animation: shimmer 1.5s 2 alternate;
}

@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}

li.badge.not-earned .badge-front {
    /* can you add grayscale over it? */
    filter: grayscale(100%);
    animation: none;
}

.badge-back {
    background: linear-gradient(135deg, #e0e0e0, #9e9e9e);
    transform: rotateY(180deg);
    justify-content: center;
}

.badge-back span {
    font-size: 1rem;
    padding: 0 5px;
}

.badge-front span.badge-name {
    background-color: black;
    width: 100%;
    padding: 5px;
    position: relative;
    top: 8px;
    display: none;
}

.badge.not-earnable span.badge-name {
    display: none;
}

.badge.not-earnable .badge-back {
    justify-content: center;
}

.not-earnable span.badge-description {
    margin-bottom: 15px;
}

.earnable span.badge-description {
    display: none;
}

/* .badge-back:hover span.badge-description {
    display: block;
} */

.not-earnable .badge-front,
.not-earnable .badge-back {
    border-radius: 50%;
    border: 2px solid gold;
}

.not-earnable .badge-back {
    background: rgb(255, 215, 0);
}

.earnable .badge-front,
.earnable .badge-back {
    border-radius: 50%;
}

.earnable.earned .badge-back {
    background: #9e1d32;
}

.badge.not-earnable:hover .badge-back {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
        100%;
    background-repeat: no-repeat;
    animation: shimmer 1.5s 2 alternate;
}

.not-earnable .badge-back span {
    /* I want to make this look like the text is etched into the background */
    color: #9e8814;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3),
        0px -1px 0px rgba(0, 0, 0, 0.7);
}
.earnable.earned .badge-back span {
    color: white;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3),
        0px -1px 0px rgba(0, 0, 0, 0.7);
}

span.badge-earned {
    font-size: 0.8rem;
}

button.share-button,
button.full-screen-button,
button.edit-team-button {
    font-size: 1rem !important;
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: 20px;
}

button.full-screen-button {
    display: none;
}

.modal button.full-screen-button {
    display: inline;
}

button.share-button svg,
button.full-screen-button svg,
button.edit-team-button svg {
    fill: white;
    width: 2rem;
    height: 2rem;
}
.team-page-clickable-header span {
    display: none;
}

span.score-rank {
    color: #666;
    font-size: 0.8rem;
    font-weight: bold;
}

span.score-rank {
    position: absolute;
    top: 0;
    right: 5px;
}

section.team-page-stats.team-page-content-section {
    background-color: white;
}

.modal a.prev-team-side,
.modal a.next-team-side {
    display: none;
}
button.btn.btn-primary.join-team-btn {
}

.join-team-btn {
    background-color: lightgreen;
    color: #000;
    font-size: 1.3rem;
    border: 0;
    padding: 10px 15px;
    width: 200px;
    line-height: 1.3rem;
    margin: 0;
    position: relative;
    top: -15px;
    font-weight: 700;
}
.leave-team-btn {
    background-color: lightcoral;
}
.game-page .modal span.team-name-team-text {
    color: white;
}
ul.medal-list.category-medals {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    justify-content: space-between;
}
.team-page-team-avatar {
    border: 5px solid white;
    height: 100px;
    width: 100px;
    position: relative;
}
.team-page-header-left {
    margin-right: 20px;
}

button.edit-team-button.edit-avatar-button {
    font-size: 1rem;
}

button.edit-team-button.edit-avatar-button svg {
    width: 1rem;
    height: 1rem;
}

button.edit-team-button.edit-avatar-button {
    position: absolute;
    right: 0;
    top: 0;
}
.team-page-header-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    padding-top: 10px;
    margin-bottom: 20px;
}
span.team-name-team-membership-status {
    font-size: 0.9rem;
    background-color: #13ff13;
    color: black;
    padding: 0px 8px;
    border-radius: 10px;
    max-width: 100px;
    text-align: center;
    margin-top: 10px;
    font-weight: 500;
    text-transform: uppercase;
}
.edit-team-avatar {
    min-width: 250px;
    min-height: 250px;
    background-color: black;
    margin: 10px 20px;
}
.edit-team-avatar-section {
    display: flex;
    margin: 20px;
}
.edit-team-avatar-wrapper {
    flex: 1;
    min-width: 300px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
}

.edit-team-avatar-options.black-text {
    flex: 2;
}

ul.edit-team-avatar-options-tabs {
    list-style: none;
    padding-left: 0;
    display: flex;
    margin: 10px 0px 10px 20px;
    justify-content: space-between;
    border-bottom: 2px solid gray;
    cursor: pointer;
}

li.edit-team-avatar-options-tab {
    flex: 1;
    background-color: lightgray;
}
li.edit-team-avatar-options-tab:hover {
    background-color: lightblue;
}

li.edit-team-avatar-options-tab.active,
li.edit-team-avatar-options-tab.active:hover {
    background-color: lightgreen;
}
ul.edit-team-avatar-options-content-tab-list {
    list-style: none;
    padding-left: 0;
    display: flex;
    margin: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

li.edit-team-avatar-options-content-tab-list-item {
    min-width: 130px;
    min-height: 130px;
    background-color: black;
    flex: 1;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
}

span.edit-team-avatar-options-content-tab-list-item-label {
    position: absolute;
    bottom: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 4px 15px;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    left: 15%;
    right: 15%;
}

.edit-team-avatar-color-options {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.edit-team-avatar-color-square {
    width: 25px;
    height: 25px;
    background-color: black;
    margin-right: 10px;
    border: 2px solid black;
}

.edit-team-avatar-color-option {
    display: flex;
    flex: 1;
    justify-content: center;
}

.edit-team-avatar-color-option.primary-color .edit-team-avatar-color-square {
    background-color: #7f28ad !important;
}

.avatar-bg-wavy {
    background-color: #000000;
    opacity: 0.9;
    background-image: repeating-radial-gradient(
            circle at 0 0,
            transparent 0,
            #000000 20px
        ),
        repeating-linear-gradient(#7008a355, #7008a3);
}

.avatar-bg-rhombus {
    background-color: #000000;
    opacity: 0.9;
    background-image: linear-gradient(135deg, #7008a3 25%, transparent 25%),
        linear-gradient(225deg, #7008a3 25%, transparent 25%),
        linear-gradient(45deg, #7008a3 25%, transparent 25%),
        linear-gradient(315deg, #7008a3 25%, #000000 25%);
    background-position: 20px 0, 20px 0, 0 0, 0 0;
    background-size: 20px 20px;
    background-repeat: repeat;
}

.avatar-bg-zigzag {
    background-color: #000000;
    opacity: 0.9;
    background-image: linear-gradient(135deg, #7008a3 25%, transparent 25%),
        linear-gradient(225deg, #7008a3 25%, transparent 25%),
        linear-gradient(45deg, #7008a3 25%, transparent 25%),
        linear-gradient(315deg, #7008a3 25%, #000000 25%);
    background-position: 20px 0, 20px 0, 0 0, 0 0;
    background-size: 40px 40px;
    background-repeat: repeat;
}

.avatar-bg-zigzag-3d {
    background-color: #000000;
    opacity: 0.9;
    background: linear-gradient(135deg, #7008a355 25%, transparent 25%) -20px 0/
            40px 40px,
        linear-gradient(225deg, #7008a3 25%, transparent 25%) -20px 0/ 40px 40px,
        linear-gradient(315deg, #7008a355 25%, transparent 25%) 0px 0/ 40px 40px,
        linear-gradient(45deg, #7008a3 25%, #000000 25%) 0px 0/ 40px 40px;
}
