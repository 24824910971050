section.live-scoring {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    margin: 0;
    max-width: 100vw;
    z-index: 1000;
}

.close-game {
    cursor: pointer;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    border: green;
    padding: 3px 10px;
    width: auto;
    font-size: 2rem !important;
    line-height: 1rem;
    font-weight: 700;
    margin-left: 20px;
}

.close-game span {
    position: relative;
    margin-left: 5px;
    top: 2px;
    font-size: 1.25rem;
}

.live-scoring header,
.live-scoring footer {
    background-color: lightblue;
    /* min-height: 150px; */
    /* max-height: 10vh; */
    color: black;
    font-size: 2rem;
    padding: 0 20px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.live-scoring header h2,
.live-scoring footer h2 {
    color: black;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10px 0;
}

.live-scoring header h2 span,
.live-scoring footer h2 span {
    font-size: 1.5rem;
    font-weight: normal;
    color: black;
}

span.admin-banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: red;
    font-size: 1rem;
    padding: 5px;
}

.live-scoring header span.live-scoring-venue-name {
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    margin: 0;
    line-height: 1.5rem;
}

span.live-scoring-header-date-time {
    font-size: 1rem !important;
    margin: 0;
    line-height: 1rem;
}

.live-scoring header span.live-scoring-venue-name:hover {
    text-decoration: underline;
}

p.live-scoring-venue-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p.live-scoring-venue-edit * {
    margin: 0 5px;
    font-size: 1rem;
}

button.live-scoring-venue-select-button {
    background-color: red;
    border: 0;
    padding: 5px 15px;
}
.live-scoring footer {
    background-color: lightpink;
}

.live-scoring > main {
    background-color: #fff;
    flex: 1;
    width: 100vw;
    margin: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
section.live-scoring.live-scoring-admin-true {
    padding-top: 30px;
}

.scoring-category {
    cursor: pointer;
    color: black;
}
.scoring-category:hover {
    text-decoration: underline;
}

ul.live-scoring-list {
    padding: 0;
    list-style: none;
    margin: 0;
    padding-bottom: 2vh;
}

.live-scoring-list li {
    color: black;
    font-size: 2rem;
    border-bottom: 1px solid #ccc;
    padding: 20px 50px;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p.team-display {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    line-height: 2.2rem;
    flex: 1;
    text-align: left !important;
}

.team-display span.team-name {
    font-size: 1.5rem;
}

span.team-correct {
    text-align: right !important;
}

span.round-name-and-number {
    color: black;
}

li.scoring-team.missing > * {
    flex: 1;
    text-align: center;
}

.scoring-team.missing {
    background-color: rgba(255, 0, 0, 0.25);
}

.scoring-team.missing .team-touched {
    color: red;
}

.live-scoring li.live-scoring-venue-day,
.live-scoring li.live-scoring-selected-category,
.live-scoring li.game-team-selected {
    background-color: lightgoldenrodyellow;
}
.live-scoring li.live-scoring-not-available-category {
    background-color: lightgray;
}
.live-scoring li:hover {
    background-color: lavender;
    font-weight: bold;
}

.live-scoring li.game-team-new {
    background-color: lightgreen;
    text-align: center;
    justify-content: center;
}

li.game-team-new.crossed-out {
    background-color: lightcoral;
    font-style: italic;
    text-decoration: line-through;
}

section.old-games {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.day-buttons-wrapper {
    width: 100%;
}

p.savingGame {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 300;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
    padding: 50px;
    box-sizing: border-box;
    margin: 0;
    display: flex;
}

p.savingGame span {
    color: black;
    font-weight: 700;
    margin: auto;
    font-size: 3rem;
}

input.team-search {
    margin: 10px auto;
    width: 100%;
    font-size: 1.5rem;
    color: black;
    text-align: center;
    box-sizing: border-box;
}

.scoring-round h2,
.scoring-round h3 {
    color: black;
}

.scoring-round li h3 {
    font-size: 1.25rem;
}

.scoring-round ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.team-scoring-view h2 {
    color: black;
}

.team-scoring-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.team-scoring-list li {
    border-bottom: 0;
    padding: 0;
    width: 100%;
}

.team-scoring-list li:hover {
    background-color: white;
}

.team-scoring-list li button {
    background-color: lightgray;
    border: lightgray;
    margin: 8px auto;
    padding: 20px;
    width: 80%;
    font-size: 1.25rem;
}

.team-scoring-list li button:hover {
    background-color: darkgrey;
    border: darkgrey;
}

.team-scoring-list li button.correct {
    background-color: #0db52a;
    border: #0db52a;
}

.team-scoring-list li button.incorrect {
    background-color: lightcoral;
    border: lightcoral;
}

.live-scoring-list li span {
    color: black;
    flex: 1;
}
span.round-item-category {
    text-align: center;
    display: flex;
    flex-direction: column;
}
span.round-item-subcategory {
    font-size: 1.5vw;
    line-height: 1.5vw;
    margin: 0;
    padding: 0;
}

span.round-item-score,
span.remove-team {
    text-align: right;
}

.scoreboard h2 {
    color: black;
}

button.live-scoring-teams-button {
    background-color: gray;
    border: gray;
    margin-right: 30px;
}

button.live-scoring-teams-button.selected {
    background-color: green;
    border: green;
}

button.scoreboard-button {
    background-color: gray;
    border: gray;
    width: 150px;
}

button.scoreboard-button.selected {
    background-color: green;
    border: green;
    padding: 10px 20px;
    width: auto;
    font-size: 1rem !important;
    line-height: 1rem;
}

a.slideshow-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

a.slideshow-button span {
    position: relative;
    margin-left: 5px;
    top: 1px;
    font-size: 1.2rem;
}

button.finalize-score,
button.finalize-score-missing {
    margin-right: 30px;
}

button.finalize-score {
    background-color: green;
    border-color: green;
    width: 150px;
}

h2.team-scoring-team-name {
    display: flex;
    flex-direction: column;
}

.team-scoring-team-name .display-name {
    color: black;
}

.team-scoring-team-name .team-name {
    font-size: 1.25rem;
}
li.scoring-team {
    align-items: center;
}

small.finalized-game {
    color: green;
    text-align: center;
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
}

.team-info span {
    text-align: center;
}

span.team-name {
    font-size: 1.25rem;
}

span.display-name {
    font-weight: 700;
    font-size: 1.5rem;
}

span.live-scoring-header-fourth-line {
    display: none;
}

.footer p {
    flex: 1;
}

p.footer-link-email {
    flex: 3 1;
}
.team-name-edit {
    color: black !important;
    text-align: center !important;
}
section.host-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    margin: 0;
    border-top: 2px solid #ccc;
}

.host-controls h2 {
    font-size: 1.5rem !important;
    margin: 0;
}

section.slide-controls {
    display: flex;
    flex-direction: row; /* Make sure it's row */
    flex-wrap: wrap; /* Wrap items if necessary */
    justify-content: space-around; /* Distribute buttons evenly */
    width: 100%; /* Full width of the container */
}

.slide-buttons {
    width: 100%;
    position: absolute;
    bottom: 30.5vh;
}

p.slide-number {
    font-size: 2rem;
    margin: 10px 20px 0;
    line-height: 2rem;
}

.slide-controls select {
    color: black;
    width: 100%;
    margin: 5px 9px;
    border: 2px solid black;
    font-size: 1.25rem;
}

.live-scoring-main-left {
    width: 60vw;
    border-right: 5px solid #ccc;
}
button.show-controls {
    margin: 5px auto;
    padding: 2px 10px;
    background-color: gray;
    border-color: gray;
}
.live-scoring footer {
    display: none;
}
button.live-scoring-controls-button {
    display: flex;
    flex-direction: column;
    background: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0 10px;
    border: 0;
    padding: 10px;
}

button.live-scoring-controls-button:disabled {
    background: var(--color-bg-secondary);
    border-color: var(--color-bg-secondary);
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

button.live-scoring-controls-button svg {
    color: black;
    fill: black;
    font-size: 2rem;
}

button.live-scoring-controls-button:disabled svg {
    fill: var(--color-text-secondary);
}

span.controls-button-label {
    color: black;
    font-weight: 500;
    font-size: 0.9rem;
}
button.live-scoring-controls-button:disabled span.controls-button-label {
    color: var(--color-text-secondary);
}
span.next-prev-slide-label {
    font-weight: 700;
    font-size: 3rem;
}
.next-slide span.next-prev-slide-label {
    font-size: 10rem;
    line-height: 5rem;
}
span.next-prev-slide-title {
    font-size: 1.5rem;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
span.next-prev-slide-title > span {
    color: white;
}
.controls-panel h2 {
    font-size: 1.75rem !important;
    margin: 10px 0 0;
}
svg.help-toggle {
    fill: #ffa500;
    font-size: 3rem;
    cursor: pointer;
}
svg.help-toggle-small {
    fill: #ffa500;
    cursor: pointer;
    display: inline-block;
}

.slide-dropdown {
    width: 98%;
    display: flex;
    align-items: center;
}
.current-slide-label {
    font-size: 1.5rem !important;
    margin: 5px;
}
section.answer-controls {
    border-top: 2px solid #cccc;
    width: 100%;
    margin: 30px 0 0;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
}
section.question-controls {
    display: flex;
    flex-direction: column;
}
.controls-panel.hide {
    flex: 1;
    padding: 0;
    position: relative;
}
.slide-buttons button.prev-slide {
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    border: 0;
    z-index: 2;
    padding: 30px 50px 0;
}

.slide-buttons button.next-slide {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 29.5vh;
    background-color: green;
    border: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.slide-buttons button.next-slide:disabled {
    background-color: gray;
}
.next-slide-preview-text {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
}
.next-slide-preview-text * {
    color: black;
}

.next-slide-preview-text span {
    margin-bottom: 10px;
}
.modal canvas.game-qr-button {
    width: 50vw !important;
    height: 50vw !important;
    max-width: 500px;
    max-height: 500px;
}
.controls-panel.show {
    margin: 0 auto;
}
.add-venue-autocomplete {
    display: flex;
    flex-direction: column;
    align-items: center;
}
button.add-venue-btn {
    position: absolute;
    top: -110px;
    right: 60px;
    z-index: 10000;
    padding: 5px 20px;
    background-color: lightgreen;
    border: 0;
}
button.add-venue-btn.toggled {
    background-color: lightcoral;
}

.create-game-form {
    position: relative;
}
.search-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
}

.search-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    padding: 20px;
}

.search-result * {
    margin: 0;
}

.search-result:hover {
    background-color: lightyellow;
}
p.error {
    font-size: 1.2rem;
    font-weight: 700;
    color: red !important;
}
.search-result.selected {
    background-color: lightgreen;
}
ul.search-result-photos-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.search-result-photos {
    padding-top: 20px;
    border-top: 2px solid black;
    margin-top: 10px;
}

h3.search-result-photos-title {
    font-size: 1.25rem;
}
.search-results h2.search-results-title {
    font-size: 1.5rem;
}

.search-result {
    border: 2px solid gray;
}
img.search-result-photo.selected {
    border: 5px solid gold;
}

img.search-result-photo:hover {
    border: 3px solid silver;
}
button.search-result-save {
    padding: 5px 20px;
    background-color: black;
    color: white !important;
    border: 0;
    margin-top: 10px;
    font-size: 1.5rem;
}

.team-header > * {
    margin: 0 10px;
}
.team-header button {
    padding: 0px 10px;
    line-height: 1.5rem;
    background-color: black;
    color: white !important;
    border: 0;
}
button.done-button.mobile {
    display: none;
}
.team-header {
    border-bottom: 3px solid lightgray;
}
.controls-panel {
}

.live-scoring .category-selection-item {
    height: auto !important;
    width: 30%;
    margin: 10px 0;
    padding: 30px 0;
    cursor: pointer;
}

.live-scoring .category-selection-name {
    font-size: 2rem;
    line-height: 2rem;
    color: black;
}

.live-scoring .slide.category-selection-slide {
    display: flex !important;
    justify-content: space-between;
}
.live-scoring span.selected-team-name {
    font-size: 1.25rem;
    line-height: 1.25rem;
    padding: 0;
}
button.time-to-score-button {
    background-color: green;
    font-size: 2rem;
    border: 0;
}
.live-scoring .add-team-modal .modal-content {
    height: 70vh;
    width: 70vw;
}
span.team-players,
span.team-players svg {
    font-size: 1.25rem;
    color: black;
    fill: black;
    display: flex;
    /* align-items: center; */
}

span.team-players svg {
    position: relative;
    top: 2px;
}
span.round-item-category-label svg {
    fill: black;
    font-size: 1rem;
    margin-left: 10px;
}

span.round-item-category-label small {
    color: gray;
    opacity: 0.5;
}
.live-scoring-list > p > button {
    background-color: black;
    border: 0;
    padding: 5px 15px;
    font-size: 1.2rem;
}
/* Apply styles when the button has the class "never-opened" */
.never-opened {
    animation: flash 1s 3; /* Flash animation a few times */
    color: #ffffff; /* White text for contrast */
    box-shadow: 0 0 15px 5px lightgreen; /* Glowing effect */
    font-weight: bold; /* Make the text stand out */
    cursor: pointer; /* Pointer cursor for interactivity */
}

/* Define the flash animation */
@keyframes flash {
    0%,
    100% {
        opacity: 1; /* Fully visible */
        transform: scale(1); /* Normal size */
    }
    50% {
        opacity: 0.5; /* Semi-transparent */
        transform: scale(1.1); /* Slightly larger */
    }
}
p.viewing-scoreboard-description.black-text {
    font-size: 1.25rem;
    width: 60%;
    margin: 10px auto;
}

button.back-to-controls {
    background-color: black;
    border: 0;
}
.slide-buttons button.prev-slide:disabled {
    background-color: silver;
}
.live-scoring-header-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

li.live-scoring-go-back {
    justify-content: flex-start;
    line-height: 1.25rem;
    font-size: 1.25rem;
    background-color: gray;
    color: white;
}

li.live-scoring-go-back svg {
    fill: white;
    margin-right: 10px;
}
.team-scoring-view {
    padding: 30px 0 50px;
    position: relative;
}
span.live-scoring-header-third-line,
span.live-scoring-header-third-line span {
    font-size: 0.9rem !important;
    line-height: 1rem;
    color: #666 !important;
}
.team-scoring-view li.live-scoring-go-back {
    list-style: none;
    padding: 15px 50px;
    text-align: left;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}
.team-scoring-view li.live-scoring-go-back svg {
    position: relative;
    top: 3px;
}
span.remove-team {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    background-color: red;
    color: white !important;
    padding: 2px 10px;
    font-weight: 700;
}

li.game-team-selected {
    position: relative;
}
button.clear-search {
    background-color: unset;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 110px;
    top: 22px;
    font-size: 1.5rem;
}

.team-header {
    position: relative;
}

button.clear-search svg {
    fill: #ccc;
}
.live-scoring header.modal-header {
    box-shadow: unset;
}

ul.game-information {
    list-style: none;
    padding: 5px 3%;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: #ccc;
}

ul.game-information * {
    color: darkslategray;
}
.live-scoring li.game-info-item:hover {
    background-color: unset;
    font-weight: normal;
}

p.next-slide-preview-text.help-text {
    font-size: 1.25rem;
    margin: 5px 0;
    background-color: lightyellow;
    padding: 10px 2.5%;
    border: 10px solid orange;
    border-radius: 5px;
    width: 90%;
    margin-bottom: 0;
    position: absolute;
    top: 40vh;
    z-index: 100000;
}
.scoreboard-preview {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
}
ol.scoreboard-preview {
    overflow-y: scroll;
    min-height: 5vh;
    height: 25vh;
    border-bottom: 10px solid lightblue;
}
ol.scoreboard-preview.showing-help {
    height: 18vh;
}

.scoreboard-preview li span {
    color: black !important;
}

.scoreboard-preview li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.scoreboard-preview li span {
    flex: 1;
    font-size: 1rem;
    line-height: 1rem;
}

.scoreboard-preview .team-round {
    width: 100px;
}

ul.scoreboard-preview {
    margin-top: 10px;
    background-color: lightblue;
}

.scoreboard-preview li:nth-child(even) {
    background-color: #ccc;
}
.scoreboard-preview span.team-name {
    text-align: left;
    padding-left: 5px;
    padding-right: 0;
    flex: 2;
}
li.game-info-item {
    padding: 5px 15px 2px;
    border-radius: 20px;
}

li.game-info-item span {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: white;
}
li.game-info-item.mystery-background span {
    color: #333;
}
ul.game-information.mystery-background li span,
ul.game-information.places-background li span {
    color: #333;
}
p.top-3-message {
    width: 100%;
    background-color: lightcoral;
    padding: 6px 0;
    font-size: 1rem;
    line-height: 1rem;
    margin: 8px 0 -10px;
}
h3.tie-breaker-title {
    font-size: 2rem;
}

.tie-breakers {
    width: 95%;
    background-color: lightcoral;
    box-sizing: border-box;
    padding: 10px 5.5% 0 2.5%;
    margin-top: 10px;
}

p.tie-breaker-question {
    font-size: 1.2rem;
    font-weight: 700;
}

p.tie-breaker-answer {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
}

p.tie-breaker-error {
    font-style: italic;
    font-size: 1rem;
    margin: 20px 0;
}
button.tie-breaker-button {
    background: green;
    border: 0;
    text-transform: uppercase;
    font-size: 1.2rem;
}
button.tie-breaker-button.unset-tiebreaker {
    margin-left: 10px;
    background-color: red;
}
.mark-all-buttons button {
    padding: 5px 15px;
    border: 0;
    margin: 0 5px;
}

button.mark-all-correct {
    background-color: green;
}

button.mark-all-incorrect {
    background-color: red;
}

.mark-all-buttons button {
    font-size: 1.25rem;
    text-transform: uppercase;
}

.mark-all-buttons svg {
    position: relative;
    top: 3px;
}
button.scoreboard-button.mark-all-touched-button {
    padding: 0;
    margin: 0 15px 0 0;
}
