.calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
}

header.calendar__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.calendar__nav {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
}

li.calendar__nav-item {
    margin-left: 7px;
}

button.calendar__nav-btn {
    background-color: #f4f6f7;
    border: 0;
}

ul.calendar__week-top {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    margin: 5px 0;
}

li.calendar__week-top-item {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #f4f6f7;
    margin: 0 5px;
    border-radius: 5px;
}

span.calendar__week-top-day {
    font-weight: 100;
    font-size: 1.2rem;
    margin: 5px 0;
}

span.calendar__week-top-date {
    font-size: 2rem;
    font-weight: 700;
}

ul.calendar__week-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    margin: 0;
    flex-wrap: wrap;
}

li.calendar__week-bottom-item {
    flex: 1;
    padding: 0 5px;
    min-width: 14%;
    box-sizing: border-box;
}

button.calendar__week-bottom-template {
    width: 100%;
    margin: 0;
}

.calendar__week-bottom_template-add {
    background-color: lightcoral;
    border: 0;
    font-size: 0.8rem;
    font-weight: 500;
}

ul.calendar-event-games {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.calendar.calendar--host-dashboard ul.calendar-event-games {
    margin-top: -22px;
}

li.calendar-event-game {
    display: flex;
    flex-direction: column;
    background-color: lightblue;
    padding: 10px;
    margin: 10px 0 0 0;
    border-radius: 5px;
}

span.calendar-event-game__venue {
    font-weight: 700;
    font-size: 1.25rem;
}

span.calendar-event-game__host {
    font-weight: 100;
    font-size: 0.8rem;
}

button.calendar__week-bottom-template-edit {
    border: 0;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: lightgreen;
}
button.calendar__week-bottom-template-edit.calendar__week-bottom-template-add--game {
    font-size: 1.5vw;
    margin-top: -20px;
}

.calendar--monthly .calendar__week-bottom {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.calendar--monthly .calendar__week-bottom-item {
    min-width: auto;
    text-align: center;
    padding: 10px 10px;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    background-color: #18183e;
    position: relative;
    min-height: 10vh;
}

.calendar--monthly .calendar__week-bottom-template {
    font-size: 0.8rem;
    padding: 5px;
}

.calendar--monthly .calendar-event-game {
    background-color: lightblue;
    padding: 8px;
    margin: 5px 0;
}

.calendar__week-bottom {
    display: flex; /* Default for weekly view */
    flex-wrap: wrap;
}

.calendar--monthly
    button.calendar__week-bottom-template.calendar__week-bottom_template-add {
    display: none;
}

.calendar--monthly ul.calendar__week-top {
    display: none;
}

.calendar--monthly .calendar__title {
    color: white !important;
}

.calendar--monthly .month-date {
    position: absolute;
    top: 5px;
    left: 5px;
    font-weight: bold;
    font-size: 1rem;
}
.calendar--weekly span.month-date {
    display: none;
}
ul.calendar_days_of_the_week_header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    width: 100%;
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
}
.calendar_days_of_the_week_header li.calendar__week-top-item {
    background-color: gray;
}

.calendar_days_of_the_week_header
    li.calendar__week-top-item
    .calendar__week-top-day {
    font-weight: 700;
}
.calendar--monthly ul.calendar-event-games {
    padding-top: 20px;
}

.calendar--monthly li.calendar-event-game {
    background-color: gray;
    text-align: left;
}
.calendar--monthly span.calendar-event-game__venue {
    font-size: 1rem;
}
span.calendar-event-game__time {
    font-size: 0.8rem;
    margin-top: -5px;
}
.calendar--monthly
    li.calendar__week-bottom-item.calendar__week-bottom-item--today {
    background-color: black;
}

.calendar--monthly .calendar__week-bottom-item--today li.calendar-event-game {
    background-color: rgb(155 155 254);
}
.calendar.calendar--host-dashboard h2 {
    color: white !important;
}

.calendar.calendar--host-dashboard ul * {
    color: black !important;
}
li.calendar-event-game.calendar-event-game-view-weekly a {
    display: flex;
    flex-direction: column;
}

.calendar-event-game-view-weekly span.calendar-event-game__host {
    font-size: 1.2rem;
    font-weight: 500;
}
