.medal-list {
    list-style: none;
    padding: 0;
}

.medal {
    text-align: center;
    padding: 5px 10px;
    display: flex;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all 0.5s;
    flex-basis: 20%;
}

.medal.pictures {
    display: none;
}

.medal-center {
    font-size: 3rem;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    padding: 10px;
    border: 10px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    box-sizing: border-box;
}

.medal .medal-icon {
    fill: white;
}

.medal.none .medal-icon {
    fill: #c0c0c0;
}
.medal.none .medal-icon svg * {
    fill: #c0c0c0 !important;
}

.medal.bronze .medal-icon {
    color: #cd7f32;
}

.medal.silver .medal-icon {
    color: #c0c0c0;
}

.medal.gold .medal-icon {
    color: #ffd700;
}

.medal.platinum .medal-icon {
    color: #e5e4e2;
    display: flex;
    flex-direction: column;
}

.medal.diamond .medal-icon {
    color: #b9f2ff;
}

.medal.master .medal-icon {
    color: #9b111e;
}

.medal.bronze {
    order: 6;
}

.medal.silver {
    order: 5;
}

.medal.gold {
    order: 4;
}

.medal.platinum {
    order: 3;
}

.medal.diamond {
    order: 2;
}

.medal.master {
    order: 1;
}

.medal.bronze,
.medal.silver,
.medal.gold,
.medal.platinum,
.medal.diamond,
.medal.master {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
        100%;
    background-repeat: no-repeat;
    animation: shimmer 1.5s 2 alternate;
}

@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}

span.medal-progress-bar {
    width: 50%;
    height: 10px;
    display: block;
    border: 1px solid black;
}

span.medal-rating {
    color: #666;
    font-size: 1.2rem;
    text-align: left !important;
    text-transform: capitalize;
    font-weight: 700;
    order: 1;
    line-height: 1.2rem;
}

span.medal-remaining {
    font-size: 1rem;
    color: #666;
    font-weight: 500;
    line-height: 1;
    order: 2;
}

span.medal-footer-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin-left: 10px;
}

.medal.bronze .circular-progress::before {
    background-color: #cd7f32;
}

.medal.silver .circular-progress::before {
    background-color: #c0c0c0;
}

.medal.gold .circular-progress::before {
    background-color: #ffd700;
}

.medal.platinum .circular-progress::before {
    background-color: #e5e4e2;
}

.medal.diamond .circular-progress::before {
    background-color: #b9f2ff;
}

span.medal-icon {
    margin: 0;
    font-size: 1.5rem;
}

span.medal-label {
    text-transform: uppercase;
    font-weight: 700;
    color: #666;
    flex: 1;
    text-align: left;
}

span.medal-value {
    justify-self: flex-end;
    color: #666;
    font-weight: bold;
    font-size: 1rem;
    text-align: right;
}

span.medal-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

span.medal-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

span.medal-bottom-row {
    width: 100%;
}

span.medal-progress {
    display: block;
    width: 100%;
    background-color: #f0f0f0;
    height: 10px;
    border: 1px solid #999;
}

.medal-progress-bar.places {
    background-color: #ffff00;
}
.medal-progress-bar.history {
    background-color: #06aff0;
}
.medal-progress-bar.science {
    background-color: #92cf51;
}
.medal-progress-bar.pop.culture,
.medal-progress-bar.pictures {
    background-color: #fe7ce9;
}
.medal-progress-bar.sports {
    background-color: #70309f;
}
.medal-progress-bar.movies {
    background-color: #ff0000;
}
.medal-progress-bar.music {
    background-color: #ffbf00;
}
.medal-progress-bar.mystery {
    /* rainbow gradient */
    background: linear-gradient(
        90deg,
        #ff0000 0%,
        #ffbf00 14.29%,
        #92cf51 28.57%,
        #06aff0 42.86%,
        #70309f 57.14%,
        #fe7ce9 71.43%,
        #ffff00 85.71%,
        #ff0000 100%
    );
}

svg.medal-icon {
    width: 30px;
    height: 30px;
}

.team-page-stats-medals.team-page-content-section {
    width: 100%;
    flex-basis: 100%;
}

ul.medal-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
}

li.medal {
    width: auto;
    margin: 5px 20px;
}

li.medal.none {
    background-color: rgba(255, 255, 255, 0.5);
}

.medal-list hr {
    margin: 20px;
}

/* progress stuff */

.progress-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-progress,
.circular-progress-remaining {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 10;
}

.inner-icon {
    position: absolute;
    width: 100%; /* Adjust the size of the inner icon */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent; /* Background color behind the icon */
    border-radius: 50%;
}

.circular-progress::before,
.circular-progress-remaining::before {
    content: "";
    position: absolute;
    width: 40px; /* Adjust the inner circle size */
    height: 40px;
    border-radius: 50%;
    background-color: #222; /* Color inside the progress circle */
    top: 5px;
    left: 5px;
}

.circular-progress-remaining {
    position: absolute;
    z-index: 1;
}

.circular-progress-remaining::before {
    background-color: transparent;
}

.progress-container img {
    width: 80%;
    height: 80%;
}
