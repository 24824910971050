.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    z-index: 10;
}
.modal,
.modal * {
    box-sizing: border-box;
}

.modal .modal {
    z-index: 10;
}

.modal-content {
    margin: auto;
    max-width: 80vw;
    max-height: 80vh;
    background-color: white;
    border-radius: 12px;
    min-width: 320px;
    min-height: 320px;
    overflow: scroll;
}

header.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
}

h2.modal-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
    position: relative;
    left: 20px;
    top: 30px;
}

button.modal-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    padding: 0;
    line-height: 1rem;
    position: absolute;
    right: -5px;
    top: -2px;
    margin: 0;
    z-index: 1000;
}

.modal-close svg {
    font-size: 3rem;
}

main.modal-main {
    padding-top: 40px;
    text-align: center;
    position: relative;
}

main.modal-main form {
    border: 0;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
}
form.modal-form {
    padding: 20px;
}

.modal-form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.modal-form__input,
.modal-form__select {
    margin-bottom: 0;
    width: 150px;
    max-width: 150px;
}

.modal-form__label {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
}
.modal-form__input:focus {
    background-color: lightgray;
    font-weight: 700;
}
button.modal-form__save-btn {
    background-color: black;
    color: white !important;
    border: 0;
    font-weight: 500;
    position: relative;
    bottom: -10px;
}
.modal.modal-form__import-modal main * {
    width: 90%;
    margin: 10px auto;
}

.modal.small-header main.modal-main {
    padding-top: 0px;
    margin-top: -55px;
}
.modal.team-modal.small-header main.modal-main {
    margin-top: 0 !important;
}

.modal.small-header h2.modal-title {
    font-size: 0;
}

.modal .modal-main section {
    background: #333;
}
.modal.add-team-modal h2.modal-title {
    font-size: 1.5rem;
}

.modal.add-team-modal main.modal-main {
    margin-top: 0;
    padding-top: 40px;
}

.modal .team-search {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
}
h3.your-teams {
    font-size: 1.5rem;
    color: #333;
}

ul.team-search__user-teams-list {
    list-style: none;
    padding-left: 0;
}

.add-team-modal .header__search__results {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 90%;
    box-shadow: none;
}
h3.search-results-title {
    display: none;
}

.add-team-modal h3.search-results-title {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 0;
}
.team-search__user-teams {
    border: 2px solid #999;
    padding: 5px 20px;
    width: 80%;
    margin: 0 auto 40px;
}
li.team-search__user-teams-list-item {
    cursor: pointer;
}
li.team-search__user-teams-list-item:hover {
    background-color: lightblue;
}
.team-search__user-teams-list-item--excluded span.team-name {
    background-color: lightgreen;
    text-decoration: line-through;
}
h2.modal-title {
    width: 90%;
    text-align: left;
}
header.modal-header {
    background-color: white !important;
}
.team-modal section {
    margin-top: 0;
    border-radius: 0;
}
.live-game-modal * {
}

.live-game-modal .modal-content {
    background-color: black;
    border: 3px solid gold;
}
.live-game-modal header.modal-header {
    background-color: black !important;
}

.live-game-modal header.modal-header h2 {
    display: none;
}

h2.live-game-modal-title {
    text-transform: uppercase;
    font-size: 4vw;
    padding: 0 2vw;
}

a.live-game-modal-button,
a.live-game-modal-button:hover {
    text-transform: uppercase;
    font-size: 3vw;
    line-height: 3vw;
    background-color: gold;
    padding: 1vw 2vw 0.5vw;
    border-radius: 10px;
    margin-bottom: 3vw;
    color: black;
}
.live-game-modal .modal-close {
    background-color: gold !important;
}
.add-team-modal h2.modal-title {
    visibility: hidden;
}
.modal.game-team-modal {
    z-index: 1000;
}

.modal.game-team-modal .modal-content {
    width: 60vw;
    max-width: 60vw;
    height: 60vh;
    max-height: 60vh;
}

.modal.small-header .modal.game-team-modal main.modal-main {
    margin-top: 20px;
}
.modal.live-game-modal {
    z-index: 1000000 !important;
}
.edit-team-modal .modal-content {
    width: 80vw;
    height: 80vh;
}

.modal.edit-team-modal {
    z-index: 100;
}
