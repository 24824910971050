ul.game-template-question-number-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
li.game-template-question-number {
    background-color: lightgray;
    flex: 1;
    text-align: center;
    margin: 0 2px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid white;
    padding: 2px 5px;
}

li.game-template-question-number--selected {
    font-weight: 700;
    border: 2px solid black;
}
li.game-template-question-number--filled {
    background-color: lightgreen;
}
li.game-template-question-number--empty {
    background-color: lightcoral;
}

.game-template-question {
    padding: 10px;
    margin-top: 10px;
}
select.game-template-form-category-select {
    width: 100%;
    text-align: center;
}

label.game-template-question-label {
    font-weight: 500;
}

.modal-form__textarea {
    width: 100%;
}
span.modal-form__section-subtitle--import {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1rem;
    font-weight: 500;
    background-color: green;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
}

h2.modal-form__section-subtitle {
    position: relative;
}

p.modal-form__tiebreaker_question {
    width: 100%;
}

.modal-form__tiebreaker_question input[type="text"],
.modal-form__tiebreaker_answer input[type="number"] {
    width: 100%;
}
