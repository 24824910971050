.season-countdown {
    position: absolute;
    top: 30px;
    background-color: red;
    padding: 0px 15px;
    border-radius: 5px;
    right: 20px;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    color: white;
}

.season-countdown svg {
    position: relative;
    top: 4px;
    margin-right: 5px;
    color: white !important;
    outline: white !important;
    stroke: white !important;
}

div.season-countdown > span.season-countdown-text {
   color: white !important;
}

.season-countdown:hover {
    box-shadow: 0 0 15px 0px rgba(255, 255, 255, 0.9);
}
.season-countdown-tooltip {
    position: absolute;
    right: 0;
    font-size: 1rem;
    top: 70px;
    background: lightyellow;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 13px 2px rgba(255, 255, 255, 0.5);
}
span.season-name {
    font-size: 1.5rem;
    line-height: 1.5rem;
}

span.season-dates {
    font-style: italic;
    font-size: 0.9rem;
}

span.season-countdown-text {
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 15px;
}

span.season-countdown-description {
    font-size: 1rem;
    line-height: 1rem;
}
div.venue-page__top-ten-scoreboard .season-countdown-tooltip {
   box-shadow: 0 0 13px 2px rgba(0, 0, 0, 0.5);
}
