.venue-page.page {
    margin-top: 20px;
    /* background-color: white; */
    padding-top: 0;
}

.venue-page__header {
    display: flex;
    justify-content: space-between;
}

.venue-page__header-left {
    display: flex;
    flex-direction: column;
}

ul.venue-page__header__info {
    display: flex;
    list-style: none;
    padding-left: 0;
    align-items: center;
    margin: 0;
}

.venue-page__header-left h1 {
    text-align: left;
    margin-bottom: 10px;
}

.venue-page {
    padding: 0 1vw;
    margin-top: -40px;
}

li.venue-page__header__info__item a img {
    height: 25px;
    margin-right: 9px;
}

li.venue-page__header__info__item {
    padding: 0 5px;
    font-size: 1rem;
}
.venue-page__hero {
    display: flex;
    margin: 20px 0;
}

img.venue-page__hero__image {
    border-radius: 15px;
    height: 300px;
}

.venue-page__next-game {
    flex: 1;
    background-size: cover;
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: darken;
    padding: 20px;
    text-align: center;
}

.venue-page__next-game h2 {
    font-size: 2rem;
    margin-bottom: 0;
    color: white !important;
}

@keyframes blink {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}

.blinking-separator {
    font-size: 8rem;
    font-weight: 700;
    line-height: 8rem;
    animation: blink 1s 1;
    position: relative;
    top: -20px;
}

.venue-page__countdown-timer {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin: 0;
}

ul.venue-page__countdown-timer li {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}

span.timer-number {
    font-size: 8rem;
    font-weight: 700;
    line-height: 8rem;
}

.venue-page__top-ten-scoreboard {
    width: 75%;
    margin: 50px auto 0;
    text-align: center;
    position: relative;
}
.venue-page__no-game p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: white !important;
}
ul.venue-page__countdown-timer * {
    color: white !important;
}
