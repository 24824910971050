@media (max-width: 1024px) {
    span.medal-value {
        display: none;
    }

    span.medal-label {
        text-align: center;
    }

    span.medal-rating {
        text-align: center;
    }

    span.medal-bottom-row {
        margin: 0;
        display: inline-block;
    }
    .medal-progress-bar {
        height: 8px !important;
    }
}
