.login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login__container {
    display: flex;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 50px 30px;
    justify-content: space-between;
    align-items: stretch;
    border-radius: 50px;

    max-width: 780px;
    margin: 0 auto;
    width: 80%;
}
.login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    width: 100% !important;
    box-sizing: border-box;
}
.login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: rgb(255, 98, 62);
    width: 100%;
    text-align: center;
    align-items: center;
}
.login__google {
    background-color: transparent;
    font-size: 2.25rem;
    display: flex;
    align-items: center;
    border: 1px solid white;
    justify-content: center;
}
.login__google span {
    font-size: 1.5rem;
    padding-left: 10px;
}

.login_left {
    background-position: center;
    background-size: 100%;
    border-radius: 20px;
    position: relative;
    height: 600px;
    width: 480px;
    background-repeat: no-repeat;
}
span.new-feature-description {
    position: absolute;
    bottom: -25px;
    left: 0;
    text-align: left;
    font-style: italic;
    font-size: 1rem;
}
.login_right {
}

.forgot-password {
    font-size: 1rem;
    text-align: right;
}
.login input[type="text"],
.login input[type="password"] {
    width: calc(100% - 1.6rem);
}
.or {
    display: flex;
    align-items: center;
}

.or hr {
    flex: 1;
    background-color: #6b6774;
}
span.or-text {
    padding: 0 20px;
    color: #6b6774;
}
ul.login__features {
    /* width: 100%; */
    list-style: none;
    padding: 0;
}

li.login__feature {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 2px solid gray;
}

.login__feature img {
    max-width: 400px;
    border-radius: 20px;
}

.login_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
    min-width: 250px;
}

.login__feature span {
    font-size: 1.1rem;
    width: 80%;
    line-height: 1.1rem;
    margin-top: 20px;
    font-style: italic;
}
.login_right h2,
.login_right p {
    text-align: left;
    margin: 0;
}
.login_right h2 {
    margin-top: 20px;
}
.login_right p {
    margin-bottom: 20px;
}
