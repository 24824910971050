*,
button,
a {
    font-family: "Zain", sans-serif;
    color: black;
    text-decoration: none;
}

.zain-extralight {
    font-family: "Zain", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.zain-light {
    font-family: "Zain", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.zain-regular {
    font-family: "Zain", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.zain-bold {
    font-family: "Zain", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.zain-extrabold {
    font-family: "Zain", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.zain-black {
    font-family: "Zain", sans-serif;
    font-weight: 900;
    font-style: normal;
}

a i {
    background-color: #118bee;
    color: black;
}

a:hover {
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #efff0b,
        0 0 82px #efff0b, 0 0 92px #efff0b, 0 0 102px #efff0b, 0 0 151px #efff0b;
}

nav ul li ul li,
nav ul li ul li a {
    color: black;
}

.dark {
    background-color: black;
    color: white;
}

.dark * {
    color: white;
}

.dark.gray {
    background-color: rgb(24, 24, 62);
}

.dark .gray {
    background-color: rgb(24, 24, 62) !important;
}

.dark .splash {
    color: rgb(155 155 254);
}

.dark .splash-bg {
    background-color: rgb(155 155 254);
}

.dark button.splash-bg {
    border: 0;
}

.dark .splash-border {
    border-color: rgb(155 155 254);
}

main.dark > section {
    background-color: transparent;
    max-width: 90vw;
}
main.dark > section.live-scoring {
    max-width: 100vw;
}

.splash {
    color: rgb(24, 24, 62);
}

.splash-bg {
    background-color: rgb(24, 24, 62);
}

.splash-border {
    border-color: rgb(24, 24, 62);
}

.black-text {
    color: black !important;
}
.black-text * {
    color: black !important;
}

h1 {
    font-size: 50px;
    font-style: normal;
    font-variant: normal;

    line-height: 26.4px;
    display: block;
    width: 100%;
}
h3 {
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px;
}
p {
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
}
blockquote {
    font-size: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 30px;
}
pre {
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5714px;
}

header,
main,
footer {
    max-width: 100vw;
    padding: 0;
}

header.main-header {
    color: white;
    width: 95vw;
    max-height: 7vh;
    padding: 0;
}

header.show-false {
    display: none;
}

main.main-content {
    min-height: 90vh;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    height: 93vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

main.main-content.dark.false.main-slideshow {
    overflow: hidden;
    height: 100vh;
}

nav {
    margin-bottom: 0;
    align-items: center;
}
header nav img {
    margin: 10px 0 0 0;
}
nav ul {
    margin: 0;
}

body {
    /* background: linear-gradient(to right, #406CA2 0%, #234F77 10%, #262626 35%, #262626 65%, #601273 90%, #8C19A9); */
    /* background-image: url("/public/images/triviabg1920x1080.jpg");
    background-attachment: fixed;
    background-size: 100vw 100vh; */
    background-color: black !important;
    font-size: 1.25rem;
}

input {
    color: black;
}

main > section {
    /* border: 10px solid rgba(72, 171, 224, 0.7); */
    background-color: rgba(72, 171, 224, 0.7);
    border-radius: 15px;
    color: white;
    text-align: center;
    padding: 2vh 5vw;
    font-size: 1.25rem;
    margin: 0 auto;
    max-width: 70vw;
}

span.loading-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    background: radial-gradient(black, transparent);
    cursor: progress;
    z-index: 10000;
}
img.loading {
    margin: auto;
}

span.small-loading-wrapper {
    position: relative;
    z-index: 100000;
    top: 0;
    margin-top: 0;
    width: 100%;
}

section.standings-page {
    flex-direction: column;
}

li.active-season-true a {
    color: green;
    font-weight: 700;
}

.pink-glow-text {
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #ee5cf1,
        0 0 82px #ee5cf1, 0 0 92px #ee5cf1, 0 0 102px #ee5cf1, 0 0 151px #ee5cf1;
}

.blue-glow-text {
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #48abe0,
        0 0 82px #48abe0, 0 0 92px #48abe0, 0 0 102px #48abe0, 0 0 151px #48abe0;
}

.yellow-glow-text {
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #efff0b,
        0 0 82px #efff0b, 0 0 92px #efff0b, 0 0 102px #efff0b, 0 0 151px #efff0b;
}

.latest-scores-button b {
    background-color: green;
    border-color: green;
}

footer {
    color: white;
    text-align: center;
    max-height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    bottom: 0;
}

footer a {
    color: white;
}

footer a img {
    height: 45px;
}

.menu {
    display: none;
}

/* admin */
.list-select {
    padding: 0;
    list-style: none;
}
.list-select li {
    display: flex;
}

details form {
    margin: 10px auto;
}

.sortable {
    cursor: pointer;
}

.icon-small {
    width: 15px;
    height: 15px;
}

.standings-wrapper {
    display: flex;
    align-items: flex-start;
}

.standings-wrapper > table {
    flex: 3;
    white-space: unset;
    margin-right: 30px;
}

.standings-wrapper > div {
    flex: 1;
}

.standings-wrapper > div table {
    white-space: unset;
}

.standings-wrapper section {
    flex: 1;
    flex-direction: column;
    padding-left: 20px;
}

.standings-wrapper section form {
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

form.daily-scores {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0px;
    box-shadow: none;
}

.standings-wrapper input#date {
    margin-bottom: 0;
}

details {
    width: 100%;
}

select,
input,
textarea {
    color: black;
}

hr {
    margin: 0;
}

.hide {
    display: none;
}

.show-index-0 {
    display: unset;
}

.width-10 {
    width: 10%;
}

.width-20 {
    width: 20%;
}

.width-30 {
    width: 30%;
}

.width-40 {
    width: 40%;
}

.width-50 {
    width: 50%;
}

.width-60 {
    width: 60%;
}

.width-70 {
    width: 70%;
}

.width-80 {
    width: 80%;
}

.width-90 {
    width: 90%;
}

.width-100 {
    width: 100%;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.jc-center {
    justify-content: center;
}

.jc-space-between {
    justify-content: space-between;
}

.jc-space-around {
    justify-content: space-around;
}

.jc-space-evenly {
    justify-content: space-evenly;
}

.jc-flex-start {
    justify-content: flex-start;
}

.jc-flex-end {
    justify-content: flex-end;
}

.jc-stretch {
    justify-content: stretch;
}

.ai-center {
    align-items: center;
}

.ai-flex-start {
    align-items: flex-start;
}

.ai-flex-end {
    align-items: flex-end;
}

.ai-stretch {
    align-items: stretch;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-grow-0 {
    flex-grow: 0;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.flex-basis-auto {
    flex-basis: auto;
}

.flex-basis-0 {
    flex-basis: 0;
}

.flex-basis-100 {
    flex-basis: 100%;
}

.m-0 {
    margin: 0;
}

.m-10 {
    margin: 10px;
}

.m-20 {
    margin: 20px;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.p-0 {
    padding: 0;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.pt-0 {
    padding-top: 0;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pr-0 {
    padding-right: 0;
}

.pr-10 {
    padding-right: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pl-0 {
    padding-left: 0;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.ptb-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.ptb-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ptb-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.plr-0 {
    padding-left: 0;
    padding-right: 0;
}

.plr-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.plr-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.block {
    display: block;
}

.bg-blue {
    background-color: rgba(72, 171, 224, 0.7);
}

.bg-red {
    background-color: rgba(224, 72, 72, 0.7);
}

.bg-green {
    background-color: rgba(72, 224, 72, 0.7);
}

.bg-yellow {
    background-color: rgba(224, 224, 72, 0.7);
}

.bg-purple {
    background-color: rgba(224, 72, 224, 0.7);
}

.bg-orange {
    background-color: rgba(224, 144, 72, 0.7);
}

.bg-pink {
    background-color: rgba(224, 72, 144, 0.7);
}

.bg-black {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-white {
    background-color: rgba(255, 255, 255, 0.7);
}

.bg-gray {
    background-color: rgba(128, 128, 128, 0.7);
}

.border-none {
    border: none;
}

.ta-center {
    text-align: center;
}

.bt {
    border-top: 1px solid #ccc;
}

.bb {
    border-bottom: 1px solid #ccc;
}

.f-sm {
    font-size: 0.75rem;
}

.f-md {
    font-size: 1rem;
}

.f-lg {
    font-size: 1.25rem;
}

.f-xl {
    font-size: 1.5rem;
}
.image-center {
    display: flex;
    margin: auto;
}

.center-text {
    text-align: center;
}

.text-line {
    margin: 0 !important;
}

/* category colors */

.places-background {
    /* background-color: #ffff00; */
    /* is there a way to fade the bg color a little bit */

    background: linear-gradient(90deg, #ffff00 0%, #ffff00 100%);
}
.places-background-transparent {
    background-color: rgba(255, 255, 0, 0.58) !important;
}
.history-background {
    background-color: #06aff0 !important;
}
.history-background-transparent {
    background-color: rgba(0, 176, 240, 0.58) !important;
}
.science-background {
    background-color: #92cf51 !important;
}
.science-background-transparent {
    background-color: rgba(0, 176, 80, 0.58) !important;
}
.pop-culture-background,
.pictures-background {
    background-color: #fe7ce9 !important;
}
.pop-culture-background-transparent {
    background-color: rgba(255, 83, 246, 0.58) !important;
}
.sports-background {
    background-color: #70309f !important;
    color: white !important;
}
.sports-background-transparent {
    background-color: rgba(112, 48, 160, 0.58) !important;
}
.movies-background {
    background-color: #ff0000 !important;
    color: white !important;
}
.movies-background-transparent {
    background-color: rgba(255, 0, 0, 0.58) !important;
}
.music-background {
    background-color: #ffbf00 !important;
}
.music-background-transparent {
    background-color: rgba(255, 192, 0, 0.58) !important;
}
.mystery-background {
    /* rainbow gradient */
    background: linear-gradient(
        340deg,
        rgba(255, 255, 0, 0.58) 0%,
        rgba(0, 176, 240, 0.58) 25%,
        rgba(255, 0, 0, 0.58) 50%,
        rgba(0, 176, 80, 0.58) 75%
    ) !important;

    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #fff,
        0 0 82px #fff, 0 0 92px #fff, 0 0 102px #fff, 0 0 151px #fff;
}

.mystery-background-transparent {
    /* rainbow gradient */
    background: linear-gradient(
        120deg,
        rgba(255, 255, 0, 0.58) 0%,
        rgba(0, 176, 240, 0.58) 25%,
        rgba(255, 0, 0, 0.58) 50%,
        rgba(0, 176, 80, 0.58) 75%
    ) !important;
}

header.show-false + main.main-content {
    padding: 0 20px;
    /* margin: 0; */
}

header.show-false ~ footer.footer {
    display: none;
}
ul.header__nav__links {
    display: none;
}
header .live-game a {
    background-color: gold;
    padding: 0 10px;
    color: black;
    text-transform: uppercase;
}
.header span.live-game {
    position: absolute;
    left: 160px;
}
