section.game-template-detail {
    width: 100vw;
    padding: 0;
    margin: 0;
    max-width: 80vw;
    border-radius: 5px;
    text-align: left;
}

.game-template-detail h1 {
    font-size: 1.5rem;
    text-align: left;
    padding-left: 20px;
}

.game-template-detail header {
    display: flex;
}
form.game-template-detail-form {
    border: 0;
    width: 100%;
    max-width: 100%;
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    box-shadow: none;
    padding: 0;
}

section.categories {
    flex-direction: row;
    justify-content: space-between;
}

section.category-block {
    flex: 1;
    flex-basis: 30vw;
    gap: 20px;
    margin: 0 0.4vw;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
}

.category-block-header {
    margin: 0;
}

section.questions {
    display: flex;
    flex-direction: column;
}

section.question-block {
    box-sizing: border-box;
}
li.category-question-tab {
    border: 1px solid black;
    flex: 1;
    text-align: center;
    background-color: lightgray;
    color: darkgray;
    cursor: pointer;
}
li.category-question-tab.active {
    background-color: lightgreen;
    color: black;
}

ul.category-question-tabs {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}
