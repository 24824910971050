.live-scoring-list.scoreboard strong {
    color: #aaa !important;
    font-size: 1.25rem;
}

.live-scoring-list.scoreboard .scoreboard-name {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}