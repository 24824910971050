/* header */
header.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 101;
}

nav.header__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    position: relative;
    z-index: 101;
}

a.header__nav__link {
    display: flex;
    align-items: center;
}

img.header__nav__logo {
    margin: 0 0 0 6vw;
    height: 50px;
}

a.header__nav__link {
    flex: 1;
    padding: 10px 0;
}

ul.mobile-menu-false {
    flex: 1;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
button.dashboard__btn.logout-btn {
    background-color: red;
    display: flex;
    border-radius: 50%;
}
li.admin-header-link a {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 10px;
    margin-top: 10px;
}

li.admin-header-link a {
    text-align: center;
    margin: auto;
}
input.header__search {
    flex: 1;
    margin: 0;
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 1.5rem;
    z-index: 102;
}

/* home page */

.home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    padding-bottom: 50px;
}

.home__upcoming-games {
    flex: 1;
}

.home__standings {
    flex: 3;
    position: relative;
}

.home__recent-games {
    flex: 1;
}

.home > div {
    text-align: center;
    padding: 0 20px;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.home__upcoming-games-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.home__recent-games-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.home__recent-game-pagination-details {
    display: none;
}

.home__upcoming-game {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    box-sizing: border-box;
    margin: 10px 0;
    border-radius: 5px;
    position: relative;
    padding-top: 0;
    background-size: cover;
    background-position: center center;
}

span.home__upcoming-game-league {
    position: absolute;
    top: 7px;
    left: 10px;
    /* width: 25px; */
    height: 25px;
    overflow: hidden;
}

.home__upcoming-game-info * {
    color: white;
}

.home__upcoming-game-info {
    text-align: left;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) 25%,
        rgba(0, 0, 0, 0.2) 60%,
        rgba(0, 0, 0, 0)
    );
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    flex: 2 1;
    padding: 90px 10px 10px 10px;
    border-radius: 5px;
}

.home__upcoming-game-empty {
    flex: 1;
    /* background gradient that goes from full black to transparent black */
    background: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    border-radius: 5px;
}

.home__upcoming-game-info * {
    margin: 0;
}

img.home__upcoming-game-logo {
    width: 100%;
    height: 100%;
}
span.home__upcoming-game-distance {
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
}
span.home__upcoming-game-distance-label {
    font-size: 0.7rem;
    line-height: 0.7rem;
}

span.home__upcoming-game-distance-miles {
    line-height: 1;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: -4px;
}
h3.home__upcoming-game-venue {
    font-weight: 700;
    font-size: 1.25rem;
}
.home__upcoming-game-bottom-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.home__upcoming-game-bottom-line span {
    font-size: 0.8rem;
    margin-right: 10px;
}

/* standings */

.home__standings-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1 1;
}

.home__standings-team {
    width: 100%;
    order: 4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    padding: 5px 0;
    cursor: pointer;
    margin: 0 0 5px;
}

.home__standings-team.home__standings-team-header {
    background-color: transparent !important;
}
.home__standings-team.home__standings-team-header * {
    font-weight: 700;
    font-size: 1.5rem;
}

.home__standings-team:hover * {
    text-shadow: 0 0 10px white;
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: 700;
}

.home__standings-team.home__standings-team-header:hover * {
    text-shadow: unset;
    color: white;
    background-color: transparent;
}

/* alternate zebra striping */
.home__standings-team:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.1);
}

/* .home__standings-team.home__standings-team-0 {
    order: 2;
}

.home__standings-team.home__standings-team-1 {
    order: 1;
}

.home__standings-team.home__standings-team-2 {
    order: 3;
} */

.home__standings-team * {
    flex: 1;
}
/* 
.home__standings-team.home__standings-team-0 .home__standings-team-rank,
.home__standings-team.home__standings-team-1 .home__standings-team-rank,
.home__standings-team.home__standings-team-2 .home__standings-team-rank {
    display: none;
} */

a.home__view-more {
    padding: 0px 15px;
    border-radius: 5px;
    width: 80%;
    margin: 20px auto 0;
}

a.home__view-more:hover {
    text-shadow: unset;
    box-shadow: 0px 0px 25px -5px white;
}

.home__standings-team.home__standings-team-2 {
    background-color: #cd7f32;
    border-color: #cd7f32;
}

.home__standings-team.home__standings-team-0 *,
.home__standings-team.home__standings-team-1 *,
.home__standings-team.home__standings-team-2 * {
    color: black;
    background-color: rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}

.home__standings-team.home__standings-team-1 {
    background-color: #c0c0c0;
    border-color: #c0c0c0;
}

.home__standings-team.home__standings-team-0 {
    background-color: #ffd700;
    border-color: #ffd700;
}

@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}

.home__recent-game {
    text-align: left;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 0 0 40px;
    position: relative;
}

h3.home__recent-game-venue {
    margin: 10px 0 0;
    font-size: 1.25rem;
}

/* alternate zebra striping */
.home__recent-game:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.1);
}
p.home__recent-game-date {
    margin: 0;
}

ul.home__recent-game-standings {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.75rem;
    margin-top: 10px;
}

li.home__recent-game-standing {
    padding: 0;
    display: flex;
    justify-content: space-between;
}

button.home__recent-game-pagination-btn {
    padding: 0 15%;
    font-size: 1.5rem;
    line-height: 2.5rem;
    height: 2.5rem;
    margin: 0;
}
a.home__view-more {
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.5rem;
}

.home__recent-game-pagination {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
button.home__recent-game-pagination-btn.home__recent-game-pagination-prev.splash-bg.splash-border.disabled {
    border-color: gray;
    background-color: gray;
    /* color: lightgray; */
}

.home__upcoming-game,
.home__recent-game {
    cursor: pointer;
}

.home__upcoming-game:hover,
.home__recent-game:hover {
    box-shadow: 0 0 10px 0 white;
}

.home__upcoming-game.home__upcoming-game-now {
    box-shadow: 0 0 10px 5px gold;
}

.header__search__results {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    background-color: white;
    box-sizing: border-box;
    z-index: 101;
    box-shadow: 0 0 10px 0 rgba(155, 155, 254, 0.5);
}

.header__search__results li,
.header__search__results li a {
    color: black !important;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    width: 100%;
    text-align: center;
}
span.team-name {
    color: black !important;
    /* border-bottom: 1px solid gray; */
}

.header__search__results ul {
    list-style: none;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
}

.header__search__results li:hover,
.header__search__results li:hover a {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4) !important;
}
.header__search__results p {
    color: black;
    font-size: 1.2rem;
    padding: 10px;
}

li.no-results-found {
    display: none;
}

li.no-results-found:nth-child(1) {
    display: block;
}
.team-search {
    flex: 1;
    position: relative;
}
span.header__nav__title {
    display: none;
}
li.make-money a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: green;
    padding: 0 15px;
    margin-right: 10px;
    border-radius: 5px;
}
li.make-money a:hover {
    box-shadow: 0 0 10px 5px gold;
    text-shadow: none;
}

li.make-money a svg {
    font-size: 2.5rem;
}
