.agenda-view {
    width: 100vw;
    margin-top: 20px;
}
.tabs {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #ccc;
}

.tabs li {
    padding: 10px 20px;
    cursor: pointer;
    border: 0;
    border-bottom: none;
    background-color: gray;
    border-radius: 5px 5px 0 0;
}

.tabs li.active {
    background-color: rgb(155 155 254);
    font-weight: 700;
    color: white !important;
}

.tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
}

.upcoming-games ul,
.past-games ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.upcoming-games li,
.past-games li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.upcoming-games li span,
.past-games li span {
    margin-right: 10px;
}

.upcoming-games li button,
.past-games li button {
    margin-right: 5px;
}

.ghost {
    background-color: #f0f0f0;
    border: 1px dashed #ccc;
    color: #999;
}

.date-header {
    font-weight: bold;
    margin-top: 20px;
}

.games-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 20px;
}

.games-list li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.venue-name {
    margin-right: 10px;
    font-weight: bold;
}

.game-status {
    margin-right: 10px;
    color: #666;
}

.team-count {
    margin-right: 10px;
    color: #333;
}
span.date-day-number {
    font-size: 2rem;
    min-width: 3rem;
}

span.date-day-month-name {
    display: flex;
    flex-direction: column;
}

span.date-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

span.date-day-month-name-top {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1rem;
}

span.date-day-month-name-bottom {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5rem;
}
ul.games-list {
    flex: 1;
}
span.game-status {
    background: orange;
    font-size: 0rem;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

span.game-status.game-status-finalized {
    background: lightgreen;
}
span.venue-details {
    text-align: left;
    margin-left: 20px;
    flex: 1;
}

span.venue-name {
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.3rem;
}

span.date-header {
    margin-top: 0;
}

span.team-count {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 300;
}
.agenda-view .game-buttons button.launch-todays-game-button {
    display: inline-flex;
    padding: 5px 15px;
    text-align: center;
}

.agenda-view .game-buttons button.launch-todays-game-button span {
    /* display: none; */
}

.agenda-view .game-buttons button.launch-todays-game-button svg {
    margin-right: 0;
    padding-right: 0;
}

span.game-buttons {
    display: flex;
    align-items: stretch;
}

a.game-page-link button {
    background: black;
    border: 0;
    color: white !important;
}

span.edit-game {
    cursor: pointer;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    background: lightgoldenrodyellow;
    border-radius: 5px;
    margin: 5px 0;
}
li.past-game {
    border-bottom: 0;
}
.past-game span.edit-game {
    display: none;
}
.no-upcoming-games {
    text-align: center;
}

.no-upcoming-games button.schedule-game-without-date {
    display: inline-flex;
}

.no-upcoming-games button.schedule-game-without-date span {
    color: white !important;
}
span.game-status.game-status-ghost {
    background: gray;
}
.ghost button.schedule-game-without-date span {
    color: white !important;
}
ul.games-list > li {
    border-bottom: 0;
}
