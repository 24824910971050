ul.live-scores-games-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
}

li.live-scores-game {
    display: flex;
    align-items: center;
}

.live-scores-game * {
    font-size: 1rem;
    font-weight: 500;
    flex: 1;
    color: black;
}

li.live-scores-game.created {
    background-color: lightgoldenrodyellow;
}

li.live-scores-game.finalized {
    background-color: lightgreen;
}

.live-scores-game.finalized * {
    font-weight: 700;
}

.live-scores-game button {
    flex: 0.2;
    margin: 0 6px;
    padding: 5px 10px;
    color: white;
}

button.live-scores-game-edit {
    background-color: orange;
    border-color: orange;
}

button.live-scores-game-delete {
    background-color: red;
    border-color: red;
}

header.live-scores-games-list-header {
    display: flex;
    justify-content: space-between;
}

.live-scores-games-list-header span {
    text-align: center;
    flex: 1;
    color: black;
    border-bottom: 1px solid black;
    padding: 0 0 3px 0;
}

.live-scores-admin-edit {
    position: absolute;
    border: 5px solid orange;
    padding: 20px;
    top: 25vh;
    left: 25vw;
    right: 25vw;
    bottom: 25vh;
    background-color: white;
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.5);
}

.live-scores-admin-edit.live-scores-admin-edit-team {
    top: 5vh;
    bottom: 5vh;
    left: 5vw;
    right: 5vw;
}

.live-scores-admin-edit * {
    color: black;
    text-align: center;
}

a.close {
    position: absolute;
    top: 5px;
    right: 5px;
}

.live-scores-admin-edit p {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.live-scores-admin-edit label {
    margin-right: 20px;
}
.live-scores-admin-edit button {
    color: white;
}

ul.live-scores-admin-edit-scores {
    list-style: none;
    padding: 0;
}

.live-scores-admin-edit-scores ul {
    display: flex;
    justify-content: space-between;
}

.scores-admin-score-button {
    border-color: white;
}

.scores-admin-score-button.correct {
    background-color: lightgreen;
}

.scores-admin-score-button.incorrect {
    background-color: lightcoral;
}
.update-final-score {
   background-color: red;
   border-color: red;
}