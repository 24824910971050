.answer-sheet-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.answer-sheet-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.close {
    border: 0;
    color: white !important;
    right: 10px !important;
    left: unset !important;
    top: 0 !important;
}

.rounds-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.answer-sheet-round {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    flex-basis: calc(50% - 32px);
    text-align: left;
}

.answer-sheet-round h3 {
    margin: 0;
    font-size: 1rem;
    padding: 10px;
    line-height: normal;
}
.answer-sheet-round ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    justify-content: space-between;
}

header.answer-sheet-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.answer-sheet-header > * {
    flex: unset;
    width: auto;
    margin: 20px 0 10px;
    line-height: normal;
    font-size: 1.5rem;
    color: #808080 !important;
}

li.answer-sheet-question.correct {
    background-color: lightgreen;
}

li.answer-sheet-question.incorrect {
    background-color: lightcoral;
}

li.answer-sheet-question {
    border-radius: 5px;
    flex-basis: 20%;
    margin: 6px 2%;
    text-align: center;
}
.game-team-modal .team-members {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.game-team-modal .team-member {
    background-color: #eee;
    padding: 5px 15px;
    flex: 1;
    margin: 0 10px;
    cursor: pointer;
}
.game-team-modal .team-member.me {
    background-color: lightblue;
}
a.visit-team-page {
    padding: 5px 15px;
    color: white !important;
    background-color: green;
    border: 0;
    font-size: 1rem;
    margin: 0;
}
.team-members h2 {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    text-align: left;
    font-weight: 400;
    margin-left: 1%;
}
