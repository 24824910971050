.register {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.register__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    width: 100% !important;
    box-sizing: border-box;
}
.register__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: rgb(255, 98, 62);
    width: 100%;
    text-align: center;
    align-items: center;
}

.register_left {
    flex: 1 1;
    background-position: center;
    background-size: 100%;
    border-radius: 20px;
    position: relative;
}
span.new-feature-description {
    position: absolute;
    bottom: -25px;
    left: 0;
    text-align: left;
    font-style: italic;
    font-size: 1rem;
}
