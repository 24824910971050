section.game-page.presentation-slideshow {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 0;
    min-width: 100vw;
    max-height: calc(100vw * 0.5625);
    height: calc(100vw * 0.5625);
    min-height: calc(100vw * 0.5625);
    position: relative;
    left: -20px;
    border-radius: 0;
    background: url("../../../public/images/triviabg1920x1080.jpg") no-repeat
        center center;
    background-size: cover;
}

.slideshow * {
    color: white !important;
    font-size: 1.5vw; /* Updated */
}

.slideshow {
    height: calc(100vw / 16 * 9);
    width: 100vw;
}

.slideshow .slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}

main.split {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin: 0;
}

section.split-left {
    flex: 1;
    background: none;
}

main.split > section {
    padding: 0;
}

section.split-right,
section.split-left {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

section.split-right {
    flex: 1 1;
    background: none;
    border-radius: 0;
    height: 90%;
}

footer.slidefooter {
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-end;
    width: 100%;
    padding: 0 2vw 1vw; /* Converted */
    position: absolute;
    bottom: 0;
}

.welcome-title {
    font-family: "Tilt Neon", sans-serif;
    color: white !important;
    font-size: 5vw; /* Updated */
    text-transform: uppercase;
    /* yellow glow to look like a neon light */
    text-shadow: 0 0 10px #fdfb21, 0 0 20px #fdfb21, 0 0 30px #fdfb21,
        0 0 40px #fdfb21, 0 0 50px #fdfb21, 0 0 60px #fdfb21, 0 0 70px #fdfb21;
}

p.trivia-title {
    font-family: "Neonderthaw", cursive;
    color: white;
    font-size: 11vw; /* Updated */
    text-shadow: 0 0 10px #a51d93, 0 0 20px #a51d93, 0 0 30px #a51d93,
        0 0 40px #a51d93, 0 0 50px #a51d93, 0 0 60px #a51d93, 0 0 70px #a51d93;
}

p.night-title {
    font-family: "Tilt Neon", sans-serif;
    color: white !important;
    font-size: 5vw; /* Updated */
    text-transform: capitalize;
    text-shadow: 0 0 10px #0fd1f4, 0 0 20px #0fd1f4, 0 0 30px #0fd1f4,
        0 0 40px #0fd1f4, 0 0 50px #0fd1f4, 0 0 60px #0fd1f4, 0 0 70px #0fd1f4;
    border: 4px solid #fdfb21;
    border-radius: 2.5vw; /* Converted */
    width: auto;
    padding: 2vw; /* Converted */
    line-height: 5rem;
    height: 90px;
    box-shadow: 0 0 10px 1px #fdfb21;
}
p.starts-now-title {
    font-family: "Tilt Neon", sans-serif;
    color: white !important;
    font-size: 9vw; /* Updated */
    text-transform: uppercase;
    margin-top: 10vw; /* Converted */
    text-shadow: 0 0 10px #fdfb21, 0 0 20px #fdfb21, 0 0 30px #fdfb21,
        0 0 40px #fdfb21, 0 0 50px #fdfb21, 0 0 60px #fdfb21, 0 0 70px #fdfb21;
    border: 4px solid #0fd1f4;
    border-radius: 2.5vw; /* Converted */
    width: auto;
    padding: 2vw; /* Converted */
    line-height: 5rem;
    height: 90px;
    box-shadow: 0 0 10px 1px #0fd1f4;
}

p.footer-logo {
    width: 5vw !important; /* Updated */
}
footer.slidefooter p {
    width: auto;
    margin: 0 2vw 0 0; /* Converted */
}

p.host-logo {
    flex: 1;
    height: 10vw; /* Updated */
    display: flex;
    align-items: center;
    justify-content: center;
}

p.company-facebook,
p.company-instagram {
    margin: 0;
    line-height: 1vw; /* Updated */
    height: 1.5vw; /* Updated */
}

p.company-facebook a,
p.company-instagram a {
    line-height: 1vw; /* Updated */
    height: auto;
}

/* generic black box */
.slide h1 {
    color: #fdff04 !important;
    text-transform: uppercase;
    font-size: 10vw; /* Updated */
    margin: 2vw 0 0; /* Converted */
    line-height: 8vw;
    text-shadow: 0.15vw 0.15vw red;
}

.question-slide-number {
    text-transform: uppercase;
    font-size: 10vw;
    text-shadow: 0.15vw 0.15vw red;
    color: #fdff04 !important;
    position: absolute;
    left: 3vw;
    top: -1.5vw;
}

h1.slide-title {
    position: absolute;
    top: 0;
}

.slide-black-box {
    position: absolute;
    top: 9vw;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 2vw;
    left: 2vw;
    right: 2vw;
    bottom: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.slide > p {
    flex: 1;
    width: 80%;
    margin: 0 auto 5vw; /* Converted */
    background: rgba(0, 0, 0, 0.7);
    padding: 2vw; /* Converted */
    border-radius: 5vw !important; /* Converted */
    font-size: 3vw; /* Updated */
    line-height: 3vw; /* Updated */
    text-align: left;
}

.slide.offer-slide > p {
    text-align: center;
}

.slide > p > ul > li,
.slide > p > ul > li > strong {
    font-size: 3vw; /* Updated */
    line-height: 3vw; /* Updated */
    text-align: left;
    font-weight: 300;
}
.slide > p > ul > li > strong {
    font-weight: 700;
}
section.start-slide-wrapper {
    margin: 15vw auto 0; /* Converted */
}

.start-slide p.trivia-title {
    font-size: 15rem;
}

.slide.category-selection-slide {
    flex-direction: row;
    flex-wrap: wrap;
}

.category-selection-item {
    flex-basis: 30%;
    background-color: rgba(255, 0, 0, 0.7);
    margin: 1vw 1.5vw; /* Converted */
    border-radius: 2.5vw; /* Converted */
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 30%;
}

.category-selection-name {
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 4rem;
}

/* nth-child 1-4 should be order 1-4, nth-child 5-8 should be order 6-9 */
.category-selection-item:nth-child(1) {
    order: 1;
}
.category-selection-item:nth-child(2) {
    order: 2;
}
.category-selection-item:nth-child(3) {
    order: 3;
}
.category-selection-item:nth-child(4) {
    order: 4;
}
.category-selection-item:nth-child(5) {
    order: 6;
}
.category-selection-item:nth-child(6) {
    order: 7;
}
.category-selection-item:nth-child(7) {
    order: 8;
}
.category-selection-item:nth-child(8) {
    order: 9;
}

.category-selection-item.selected-team {
    order: 5;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
}
span.selected-team-name {
    font-size: 3vw; /* Updated */
    line-height: 3vw; /* Updated */
    margin: 0;
    filter: blur(10px);
    transition: filter 2s ease-in-out;
    animation: unblur 2s ease-in-out 2s forwards; /* Add animation */
    width: 90%;
}

/* Define the keyframes for the unblur effect */
@keyframes unblur {
    100% {
        filter: blur(0);
    }
}

span.selected-team-label {
    margin: 0;
}

.category-selection-item.places-background {
    color: black !important;
}

.category-selection-item.mystery-background {
    color: black !important;
}
.slide.category-slide {
    justify-content: center;
}

h1.category-slide-title {
    text-transform: uppercase;
    font-size: 22vw;
    line-height: 20vw;
    text-shadow: 0.3vw 0.3vw red;
}
.category-slide-subtitle {
    font-size: 10vw;
    line-height: 10vw;
    margin: 0;
}
.question-slide-question {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    margin: 0 10vw;
    border-radius: 5vw;
    flex-direction: column;
    margin-bottom: 1vh !important;
    transition: font-size 0.3s ease; /* Smooth resizing */
}
.question-slide-question-text {
    text-align: center;
    font-size: 7vw;
    line-height: 7vw;
    margin: 0 5vw;
}
.slide-type-answers .question-slide-question-text {
    font-size: 6vw;
    line-height: 6vw;
}
.category-selection-item.disabled {
    background-color: gray !important;
    background: gray !important;
    color: #ccc !important;
    text-decoration: line-through;
    opacity: 0.75;
}

section.scoreboard-slide-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

p.question-slide-question.mystery-background {
    color: black !important;
}
section.tip-your-host {
    left: 0;
    top: 2vw;
}
.scoreboard-slide th,
.scoreboard-slide td {
    border: 1px solid white;
    text-align: center;
    font-size: 2.2vw; /* Updated */
}

.scoregrid th,
.scoregrid td {
    min-width: 7vw;
    max-width: 7vw;
    text-wrap: auto;
    font-size: 1.5vw;
    border: 0;
}

.scoregrid thead th:first-child,
.scoregrid tr td:first-child {
    width: 18vw;
    min-width: 18vw;
    max-width: 18vw;
    text-wrap: auto;
    max-width: 18vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
table.scoregrid {
    margin-top: 10vw;
    max-width: 95vw;
    border-radius: 5px 5px 0 0;
    border: 0;
}
table.scoregrid.scroll-grid {
    margin-top: 0;
    flex: 1;
    border-radius: 0 0 5px 5px;
    max-height: 80vh;
}
.scoregrid-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

table.scoregrid {
    max-height: 20vw;
    overflow-y: scroll;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

.scrolling {
    animation: scroll linear infinite;
}

p.final-score-tagline {
    font-size: 1.5vw;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 1vw;
}
th.scoregrid-total {
    text-transform: uppercase;
}
.final-score th.scoregrid-round-header.current {
    background-color: transparent;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 0;
    color: white !important;
}
td.scoregrid-team-round.current {
    background: lightcoral;
}
.final-score td.scoregrid-team-round.current {
    background: none;
    border: 1px solid white;
}
p.final-scores-crab {
    margin-top: 5vw;
}
.end-slide section.split-left {
    align-items: center;
}
.end-slide p.trivia-title {
    line-height: 8vw;
    font-size: 8vw;
    padding: 0 5vw;
    width: 100%;
    box-sizing: border-box;
}
.leave-host-tip {
    font-family: "Tilt Neon", sans-serif;
    color: white !important;
    font-size: 9rem;
    text-transform: uppercase;
    margin-top: 10vw; /* Converted */
    text-shadow: 0 0 10px #fdfb21, 0 0 20px #fdfb21, 0 0 30px #fdfb21,
        0 0 40px #fdfb21, 0 0 50px #fdfb21, 0 0 60px #fdfb21, 0 0 70px #fdfb21;
    border: 4px solid #0fd1f4;
    border-radius: 25px;
    width: auto;
    padding: 20px;
    line-height: 5rem;
    height: 90px;
    box-shadow: 0 0 10px 1px #0fd1f4;
}
.end-slide p.leave-host-tip {
    font-size: 3vw;
    line-height: 3vw;
    height: auto;
    margin: 0;
}

canvas.end-venmo-qr {
    width: 25vw !important;
    height: 25vw !important;
}

.end-slide section.split-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-wrap: nowrap;
}

p.trivia-title.scoreboard-title {
    position: relative;
    top: 4vw;
    font-size: 8vw;
}

p.tiebreaker-tagline {
    position: absolute;
    width: 10vw;
    line-height: 2vw;
    top: 1vw;
    right: 2vw;
    background: red;
    font-size: 2vw;
    padding: 0.25vw 0.5vw;
    text-transform: uppercase;
    box-shadow: 0 0 5px #ff0000, 0 0 10px #ff0000, 0 0 15px #ff0000,
        0 0 20px #ff0000, 0 0 25px #ff0000, 0 0 30px #ff0000, 0 0 35px #ff0000;
    z-index: 1000;
}

.tiebreaker tr.team-score-row.team-score-1.team-score-final-round-true td,
.tiebreaker tr.team-score-row.team-score-0.team-score-final-round-true td,
.tiebreaker tr.team-score-row.team-score-2.team-score-final-round-true td {
    background: lightcoral;
}

tr.team-score-row.team-score-0.team-score-position-1.team-score-final-round-true
    td,
tr.team-score-row.team-score-0.team-score-final-round-true td {
    background-color: #ffd700;
    color: black !important;
    font-weight: 700;
}

tr.team-score-row.team-score-1.team-score-position-2.team-score-final-round-true
    td,
tr.team-score-row.team-score-1.team-score-final-round-true td {
    background-color: #c0c0c0;
    color: black !important;
    font-weight: 700;
}

tr.team-score-row.team-score-2.team-score-position-3.team-score-final-round-true
    td,
tr.team-score-row.team-score-2.team-score-final-round-true td {
    background-color: #cd7f32;
    color: black !important;
    font-weight: 700;
}

span.tie {
    display: inline-block;
    width: 16px;
}

p.venmo-handle {
    font-size: 3vw;
    line-height: 3vw;
    margin-top: 2vw; /* Converted */
}

.scoreboard-slide-wrapper p.question-slide-question {
    font-size: 4vw;
    line-height: 4vw;
    margin: 0 auto;
}
.question-slide-answer {
    font-size: 7vw;
    font-weight: 700;
    margin-top: 20px;
}

.question-slide-answer.show-false {
    filter: blur(35px);
    transition: filter 0.1s ease-in-out;
}
.question-slide-answer.show-true {
    transition: filter 0.75s ease-in-out;
    animation: unblur 0.75s ease-in-out 0.75s forwards;
}
section.answer-controls {
    flex: 1;
    margin: 0 0;
}

button.show-answer,
button.hide-answer {
    font-size: 1.2rem;
    padding: 5px;
    margin: 0 2vw; /* Converted */
    background-color: green;
    border-color: green;
    line-height: 1.2rem;
}

button.hide-answer {
    background-color: lightcoral;
    border-color: lightcoral;
}

.question-controls button {
    font-size: 1rem;
    padding: 5px 15px;
    margin: 0 1.5vw 4vw; /* Converted */
}

span.current-question {
    color: black;
    font-size: 1.5vw; /* Updated */
    line-height: 2.4vw; /* Updated */
}
canvas.game-qr {
    position: absolute;
    top: 10px;
    left: 10px;
}
img.welcome-trivia-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}
.slide > ul {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 2.5vw 2.5vw 2.5vw 10vw; /* Converted */
    margin: 2.5vw; /* Converted */
    height: 80vh;
    border-radius: 5vw; /* Converted */
    text-align: left;
}

.slide > ul > li {
    font-size: 6vw; /* Updated */
    line-height: 6vw; /* Updated */
    margin: 3vw 0; /* Converted */
    font-weight: 300;
}

.slide > ul > li > strong {
    font-size: 6vw; /* Updated */
}
.rules-slide > ul > li {
    font-size: 5vw; /* Updated */
    line-height: 5vw; /* Updated */
}

canvas.game-qr-for-slides {
    width: 25vw !important;
    height: 25vw !important;
}

section.final-scores-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.final-scores-wrapper p.trivia-title {
    margin: 2vw;
    font-size: 11vw;
}

.final-scores-crab img {
    width: 20vw;
}
ul.slide-rules-list {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 90vh;
    width: 90vw;
    padding: 0 0 0 4vw;
}
.split-left ul.slide-rules-list {
    width: 56vw;
    padding-left: 0vw;
    height: 60vh;
    padding-right: 4vw;
}

ul.slide-rules-list li,
ul.slide-rules-list li strong {
    text-align: left;
    font-size: 4vw;
    line-height: 4vw;
}
p.end-title {
    font-size: 3vw;
    margin-top: 6vw;
}
p.join-the-game {
    font-size: 3vw;
    margin: 1vw 0;
}
.question-slide-subtitle {
    font-size: 2.5vw;
    margin: 0 0 10px;
    font-style: italic;
}
.welcome-slide section.split-right {
    z-index: 1;
    padding-left: 15vw;
}
span.asterisk {
    font-size: 1vw;
    position: relative;
    top: -0.5vw;
    left: -0.1vw;
}
.scoregrid-wrapper {
    position: absolute;
    top: 2.5vw;
}
table.scoregrid thead {
    background: transparent;
}
.scoregrid tr th:last-child,
.scoregrid tr td:last-child {
    border-left: 10px solid black;
    display: inline-block;
}

.scoregrid tbody tr:nth-child(odd) {
    background-color: unset;
}
.scoregrid tbody tr:nth-child(odd) td {
    background-color: rgb(58 150 211);
}
.scoregrid tbody tr:nth-child(even) td {
    background-color: rgb(3 59 96);
}
p.final-score-tagline {
    position: relative;
    top: 30px;
}
.scoregrid-round-header.current span.scoregrid-round-label {
    color: black !important;
}
.scoregrid-round-header.current {
    background: gold;
    padding-top: 9px;
}

.scoregrid tr th:last-child {
    border-radius: 15px !important;
}

.scoregrid thead tr {
    background-color: black;
}
