.admin-page section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.admin-page section h2 {
    width: 100%;
}

.admin-page section a {
    font-size: 1.5rem;
    font-weight: 500;
    background-color: lightblue;
    padding: 10px 25px;
    border: 1px solid black;
    flex-basis: 25%;
    margin: 10px 2.5%;
    color: black;
    text-decoration: none;
}

.admin-page section a:hover {
    background-color: lightgreen;
    cursor: pointer;
}

.admin-page section.content-admin a {
    background-color: lightpink;
}
.admin-page section.administrative-admin a {
    background-color: lightgoldenrodyellow;
}

/* Questions Admin */
input.question-title {
    min-width: 25vw;
}

/* Scores Admin */
form.bulk-scores {
    width: 100%;
    max-width: 100%;
}

button.btn.btn-success {
    background-color: forestgreen;
    border-color: forestgreen;
}

.add-scores {
    margin: -20px 0 30px 0;
}

.add-row {
    background-color: green;
    border: 1px solid green;
    margin-right: 10px;
}

.delete-button {
    background-color: red;
    border: 1px solid red;
    margin: 0;
    padding: 5px 10px;
}

.page-admin-page-list {
    list-style: none;
    cursor: pointer;
}

.page-box {
    padding: 5px 10px;
}

.color-preview {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    margin-right: 10px;
}

.day-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

h2.old-scores-header {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}
.users-admin-page select {
    color: black;
}
.venues-admin-page select,
.venues-admin-page input {
    color: black;
}
.teams-admin-page button.selected {
    background-color: lightgreen;
    color: black;
    border-color: lightgreen;
}
section.merge-teams {
    width: 100%;
    margin: 20px 0;
    border: 3px solid white;
}
span.merge-team-name {
    font-weight: 700;
    font-size: 1.25rem;
}

.save-banner {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.venues-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
    list-style: none;
}

.venue-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.333% - 20px);
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.venue-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.venue-name {
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.venue-location {
    display: block;
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
}

.venue-distance {
    display: block;
    font-size: 0.875rem;
    color: #888;
}

.users-admin-page .users-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
    list-style: none;
    justify-content: space-evenly;
}

.users-admin-page .user-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(25vw - 20px);
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.users-admin-page .user-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.users-admin-page .user-name {
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.users-admin-page .user-email {
    display: block;
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
}

.users-admin-page .user-role {
    display: block;
    font-size: 0.875rem;
    color: #888;
}
.users-admin-page h1 {
    text-align: center;
    margin-top: 60px;
}
.users-area,
.hosts-area {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
}
.search-area button {
    padding: 5px 20px;
}
.search-area {
    align-items: center;
    justify-content: center;
}
.search-area input {
    margin: 0 10px;
}
.modal.black-text .modal-close svg {
    fill: black;
}
p.user-profile-link {
    position: absolute;
    top: -70px;
    right: 50px;
    text-align: right;
}
.modal-main form {
    position: relative;
}

.seasons-admin-page .seasons-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
    list-style: none;
    justify-content: space-evenly;
}

.seasons-admin-page .season-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(25vw - 20px);
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.seasons-admin-page .season-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.seasons-admin-page .season-name {
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.seasons-admin-page .season-active {
    display: block;
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
}

.seasons-admin-page .season-dates {
    display: block;
    font-size: 0.875rem;
    color: #888;
}

.seasons-admin-page h1 {
    text-align: center;
    margin-top: 60px;
}

.seasons-area,
.all-seasons-area {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
}

.search-area button {
    padding: 5px 20px;
}

.search-area {
    align-items: center;
    justify-content: center;
}

.search-area input {
    margin: 0 10px;
}

.modal.black-text .modal-close svg {
    fill: black;
}
