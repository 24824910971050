.team-name {
    color: #aaa !important;
}

.remove-team {
    cursor: pointer;
}

li.game-team-selected {
}

li.game-team-selected .team-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}
.live-scoring-list span.team-score {
    text-align: right;
}
