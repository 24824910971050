section.game-page {
    background-color: rgba(255, 255, 255, 1) !important;
    color: black;
    min-width: 80vw;
    margin: 0 auto;
    padding: 0;
    margin-top: 20px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

section.game-page * {
    color: black;
}

header.game-page-header {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-radius: 15px 15px 0 0;
    box-sizing: border-box;
}

.legacy header.game-page-header {
    background-color: lightcoral;
}
.live header.game-page-header {
    background-color: gold;
}

.game-page-header-left {
    text-align: left;
}

.game-page-header-right {
    text-align: right;
}
.presentation-game .game-page-header-right {
    padding-right: 0;
}

header.game-page-header * {
    color: white;
    margin: 0;
    flex: 1;
}
.modal .presentation-game .game-page-header-right {
    padding-right: 40px;
}

.game-page.live .game-page-header * {
    color: black;
}

.game-page-header-center {
    display: none;
}

.game-page.live .game-page-header-center {
    display: block;
}

.game-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow: scroll;
}

.game-body-center {
    flex: 3;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}
button.add-team,
button.add-other-team-members {
    top: 17px;
    left: 0;
    background-color: lightgreen;
    color: white;
    border: 0;
    font-size: 1.5rem;
    padding: 3px 9px;
    color: black;
}
button.join-team-as-member,
button.leave-team-as-member,
button.add-new-team,
button.edit-team-display-name {
    padding: 5px 15px 1px;
    background-color: lightgreen;
    border: 0;
    position: absolute;
    top: -33px;
    right: 50px;
    font-size: 1.2rem;
    z-index: 1;
}
button.add-new-team {
    top: -38px;
}

button.join-team-as-member {
    background-color: lightgreen;
}

button.leave-team-as-member {
    background-color: lightcoral;
}

button.edit-team-display-name {
    right: 250px;
    background-color: gold;
}
button.edit-team-display-name:disabled {
    background-color: lightgray;
}

.add-team-modal .modal-content {
    width: 90vw;
    height: 90vh;
    /* overflow: auto; */
}

.game-body-left,
.game-body-right {
    flex: 1 1;
}

ul.scoreboard-list {
    list-style: none;
    padding: 0;
}

li.scoreboard-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    cursor: pointer;
}
li.scoreboard-list-item:hover {
    background-color: #f0f0f0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
li.scoreboard-list-item.scoreboard-list-header {
    cursor: default;
}

li.scoreboard-list-item.scoreboard-list-header:hover {
    background-color: unset;
    box-shadow: unset;
}

li.scoreboard-list-item.active {
    border: 5px solid lightgreen !important;
}

li.scoreboard-list-item:nth-child(even) {
    background-color: #f0f0f0;
}

span.team-name {
    display: flex;
    flex-direction: column;
}

span.team-displayname {
    font-size: 1.5rem;
    font-weight: 700;
}

span.team-name-name {
    color: #999;
}

span.team-score,
span.team-rank {
    font-size: 2rem;
    font-weight: bold;
    padding: 0 10px;
}
.scoreboard-list-header span.team-score,
.scoreboard-list-header span.team-rank,
.scoreboard-list-header span.team-previous-score,
.scoreboard-list-header span.team-name {
    font-size: 1rem;
    font-weight: normal;
    color: black !important;
}

button.back.btn.btn-secondary {
    position: absolute;
    left: 10vw;
    top: 60px;
    font-size: 1rem;
    padding: 2.5px 5px;
    background-color: unset !important;
    color: white !important;
    border: 0;
}

span.team-rank,
span.team-score,
span.scorecard {
    flex: 1;
}

span.team-previous-score {
    flex: 1.25;
}

span.team-name {
    flex: 9;
}

span.team-previous-score {
    padding-right: 10px;
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

span.previous-score {
    color: gray;
    text-decoration: line-through;
    font-size: 0.8rem;
}
span.score-total {
    margin-top: 10px;
}

.round span.team-score.more,
.round .score-total.more {
    color: green;
}

.team-previous-score span {
    padding: 0 2px;
    line-height: normal;
}

span.scorecard {
    display: none;
}

li.scoreboard-list-item:hover span.scorecard {
    display: block;
}

ul.category-badges {
    list-style: none;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 60px);
}

.category-badges li {
    display: flex;
    flex-direction: column;
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
    border-radius: 50%;
    border: 5px solid white;
    box-shadow: 0px 0px 9px -3px rgba(0, 0, 0, 0.5);
    padding: 0px;
    position: relative;
}

.category-badges li.no-team {
    background: gray;
    opacity: 0.25;
    cursor: no-drop;
}

span.category-badge {
    /* I want the background to be like a transparent gradient that adds like a metallic effect */
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.1)
    );
}

span.category-badge svg {
    fill: black;
    color: black;
    text-shadow: 0 0 5px black;
}

span.category-badge {
    display: block;
    width: 100%;
    padding: 45px 0;
    border-radius: 50%;
    margin-top: 0;
    line-height: 0;
}
span.category-team {
    background: black;
    color: white;
    position: absolute;
    font-size: 1rem;
    padding: 5px 0;
    text-shadow: none;
    bottom: -10px;
    left: 0;
    right: 0;
}
span.category-team a {
    color: white;
}
select.scoreboard-rounds {
    margin: 0 auto;
}

span.score-total.more {
    order: 2;
    margin-top: 0;
}

span.team-score.more {
    position: relative;
    top: 4px;
}
span.previous-score {
    order: 1;
    margin-top: -10px;
}

span.icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    align-items: center;
}

h2.game-venue {
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 5px;
    margin-bottom: 0;
}

span.game-venue-label {
    flex: unset;
    margin-right: 20px;
}

.game-venue .icons a {
    flex: unset;
    margin: 0 5px;
    height: auto;
    display: flex;
}

.icons a img {
    width: 25px;
    height: 25px;
}

.team-page-content span.home__recent-game-pagination-page {
    color: #666;
}
.add-teammate-modal main.modal-main {
    padding: 50px;
}
span.team-members {
    color: #666;
    font-size: 1.5rem;
}

.team-members svg {
    fill: #666;
    font-size: 1rem;
    top: 1px;
    left: -2px;
}
.modal section.game-page {
    margin-top: 40px !important;
}

.modal header.game-page-header {
    padding-top: 20px;
}
.game-modal h2.modal-title,
.team-modal h2.modal-title {
    display: none;
}
.create-new-team {
    border: 5px solid gray;
    width: 50%;
    margin: 0 auto 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #eee;
    padding: 20px;
}

.create-new-team button {
    padding: 5px 10px;
    margin: 0 0 0 10px;
    background-color: green;
    color: white;
    border: 0;
    width: 200px;
}
.create-new-team button.cancel-create-new-team {
    background-color: red;
}

.create-new-team input {
    text-align: center;
    flex: 1;
}
.create-new-team h2 {
    width: 100%;
    display: block;
    flex-basis: 100%;
    margin: 0 0 10px;
    font-size: 1.5rem;
}
h2.add-team-title {
    margin-top: 0;
    font-size: 3rem;
    margin-bottom: 0;
}

.modal.add-team-modal main.modal-main {
    padding-top: 0;
}

p.add-team-description {
    width: 50%;
    margin: 0 auto 30px;
    font-size: 1.25rem;
}

.search-view-modal input#team-search {
    background-color: #f5f4f4;
}

ul.user-previous-teams {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;
    margin: 10px auto;
}
h3.your-previous-teams {
    margin-top: 80px;
    font-size: 1.5rem;
    margin-bottom: 0;
}

li.user-previous-team {
    flex-basis: 20%;
    background-color: lightblue;
    border-radius: 5px;
    margin: 10px 2%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
}

li.user-previous-team:hover {
    background-color: lightgreen;
}

li.search-new-team {
    background-color: lightgreen;
}

.search-new-team span.team-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.search-new-team strong {
    font-style: italic;
    font-size: 1rem;
    margin-right: 10px;
    font-weight: 400;
    margin-left: -80px;
}

.search-new-team small {
    font-size: 1.5rem;
    font-weight: 700;
}
a.add-team {
    background-color: lightgreen;
    padding: 5px 15px;
    border-radius: 5px;
}
p.game-distance {
    font-size: 1.25rem;
    line-height: 1.1rem;
    width: 260px;
    margin: 6px auto 10px;
}

button.update-location {
    background-color: lightblue;
    border: 0;
    padding: 6px 40px;
}
