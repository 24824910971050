.button-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
}

.register-and-login {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
p.search-for-team {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

input.team-search-box {
    font-size: 1.5rem;
    text-align: center;
}
.search-results {
    background-color: rgba(255, 255, 255, 1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 75%;
}

.search-results a {
    color: black;
    font-size: 1rem;
    padding: 5px 0;
}

.home h2 {
    font-size: 2rem;
}
.home .home__mobile-footer {
    display: none;
}

.home__mobile-footer-item.current {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.home__mobile-footer-item.disabled,
.home__mobile-footer-item.disabled * {
    color: gray;
}

.become-host-button {
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 20px 0;
    font-size: 1.2rem;
    font-weight: bold;

    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 10px;
}

.become-host-button:hover {
    background-color: rgba(255, 0, 0, 1); /* Fully opaque on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}
.nudeegg {
    position: absolute;
    z-index: 1000;
    left: -23vw;
    right: 0;
    top: 10vh;
    bottom: 0;
    width: 80vw;
    height: 80vh;
    background-color: white;
    overflow: hidden;
}

.nudeegg iframe {
    width: 100%;
    height: 100%;
}
.venue-modal .modal-content {
    width: 80vw;
    height: 80vh;
}
span.home__upcoming-game-time {
    flex: 1;
    text-align: right;
    margin-right: 0 !important;
}
.home__upcoming-game > span.home__upcoming-game-date,
.home__recent-game > span.home__recent-game-date {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 4px;
    top: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: center;
    padding: 0 5px;
}

span.home__recent-game-date {
    right: 6px;
    left: unset !important;
    background: rgba(255, 255, 255, 0.2) !important;
    top: 10px !important;
}

p.home__recent-game-city-state {
    margin: 0;
}

span.home__upcoming-game-date-month,
span.home__recent-game-date-month {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 300;
    padding-top: 2px;
}

span.home__upcoming-game-date-day,
span.home__recent-game-date-day {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1rem;
}
span.home__upcoming-game-date-day-of-week {
    color: black;
}

span.home__recent-game-standing-team {
    text-align: left;
    flex: 1;
}

span.home__recent-game-standing-place {
    font-size: 0.8rem;
    line-height: 1rem;
    margin-right: 2px;
}

span.home__upcoming-game-time-box {
    position: absolute;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 5px 3px;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
    top: 5px;
}
.upcoming-game-no-date {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
}

.upcoming-game-no-date p {
    margin: auto;
    width: 80%;
}
.home__upcoming-game.home__upcoming-game-currently-live {
    border: 2px solid gold;
}

/* CSS */
.button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: black;
    background: gold;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 30px;
    font-size: 2rem;
}

.button-85:before {
    content: "";
    background: linear-gradient(
        45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: gold;
    left: 0;
    top: 0;
    border-radius: 10px;
}
.loading-location p {
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.home__standings-team.user-team {
    border: 3px solid lightgreen;
}
span.home__standings-team-name {
    flex: 2;
}

.home__standings-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.home__standings-pagination-btn {
    font-size: 1.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 35px;
    margin: 10px 8px;
}

.home__standings-pagination-btn.disabled {
    background-color: gray;
}

.home__standings-pagination-btn.splash-border {
    border-radius: 5px;
}
