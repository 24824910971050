.schedule-page ul {
    list-style: none;
    padding: 0;
}

.schedule-page-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    min-height: 100vh;
    flex-wrap: wrap;
}

.schedule-page-right > div {
    left: 50px;
    top: 30px;
}

.schedule-page-left section h2 {
    margin-bottom: 0;
}

.schedule-page-left section {
    display: flex;
    flex-direction: column;
}

.venue-infobox {
    background-color: white;
    padding: 5px 7px;
    width: 100px;
    text-align: center;
    position: relative;
    color: black;
    font-weight: bold;
}

.close-button {
    position: absolute;
    top: -12px;
    right: 7px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    padding: 0;
}

.close-button:hover {
    color: #ff0000;
}
h1.page-title {
    text-align: center;
    margin-bottom: 0;
}
.schedule-page-map {
    width: 20vw;
    padding-top: 10vh;
}
.schedule-page-calendar {
    width: 70vw;
}
