.user-page {
    padding-top: 60px;
    text-align: center;
}
.user-content {
    background: linear-gradient(180deg, #fffffff2, #fffffff2),
        url("/public/images/bgtrivia_lores.jpg");
    background-repeat: repeat;
    background-size: contain;
    border-radius: 20px;
    padding: 20px;
}
button.user-logout {
    background-color: red;
    border: 0;
    padding: 5px 15px;
    text-align: center;
    width: auto;
    margin: 0 auto 20px;
}
ul.user-page-teams--list {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

li.user-page-teams--list-item {
    background-color: lightblue;
    margin: 10px;
    width: 200px;
    border-radius: 50%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.user-page-teams.black-text {
    border-top: 2px solid gray;
    margin-top: 20px;
}
