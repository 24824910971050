.icons img {
   margin: 0 10px;
}

.back {
   text-align: center;
   display: flex;
   justify-content: center;
}

.back a {
   display: flex;
}