.your-games {
    flex-direction: column;
}

.game-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}

.game-list li {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-basis: 20vw;
    margin: 20px 5px;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 9px;
}

.game * {
    color: #333;
    cursor: pointer;
}
.game.created {
    background-color: lightcoral;
}
.game.finalized {
    background-color: lightgreen;
}
