.standings-wrapper table td {
  width: 100% !important;
}
table tbody tr:nth-child(odd) {
  background-color: rgb(58 150 211 / 68%);
}
/* table tbody tr:nth-child(1) {
  background-color: goldenrod;
}
table tbody tr:nth-child(2) {
  background-color: silver;
}
table tbody tr:nth-child(3) {
  background-color: saddlebrown;
} */