.question-admin p,
.question-admin li {
    font-size: 1rem;
}
.question-admin h2 {
    text-align: center;
}

.question-admin-categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.question-admin-category {
    flex: 30%;
    margin: 1vw;
    border-radius: 15px;
    text-align: center;
}

.question-admin-category:hover {
    cursor: pointer;
}

.question-admin-category h3 {
    font-size: 3rem;
}

.question-admin-category.places-background * {
    color: black !important;
}

.question-admin-category.mystery-background * {
    color: black;
}

p.question-admin-category-count {
    font-size: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
}

span.question-admin-category-count-number {
    margin-right: 6px;
    font-weight: 700;
    font-size: 2rem;
}
h3.question-admin-selected-category {
    text-align: center;
    font-size: 2rem;
}

button.question-admin-back {
    background-color: red;
    padding: 5px 15px;
    border: 0;
}

.question-admin {
    text-align: center;
}
ul.question-admin-questions-list {
    list-style: none;
    padding: 0;
}

li.question-admin-question {
    display: flex;
}
svg.delete-question {
    fill: red;
}
.question-admin-questions {
    width: 90%;
    margin: 60px auto 0;
    border: 3px solid white;
}

.question-admin-questions h3 {
    font-size: 1.5rem;
}

.question-admin-question p {
    flex: 1;
}

p.question-admin-question-text {
    flex: 4;
}
