@media (max-width: 1024px) {
    body {
        font-size: 12px;
        background-size: 100vw;
        /* background-image: url("/public/images/mobilebgtrivia.jpg"); */
    }
    * {
        max-width: 100%;
        flex-direction: column;
        font-size: 1rem;
        box-sizing: border-box;
    }
    /* CSS code for mobile breakpoint goes here */
    nav {
        justify-content: center;
        position: relative;
    }
    h1 {
        font-size: 2rem;
    }
    a.menu {
        display: block;
        position: absolute;
        top: 3px;
        right: 5px;
    }
    nav ul.mobile-menu-false li {
        display: none;
    }

    nav ul li {
        text-align: right;
    }

    ul.mobile-menu-false li.make-money {
        display: inline-block;
        width: auto;
    }

    ul.mobile-menu-false {
        flex: unset;
        position: absolute;
        right: -10px;
    }

    li.make-money a {
        padding: 0 10px;
    }

    li.make-money a svg {
        font-size: 2rem;
    }

    li.make-money a small {
        font-size: 0.75rem;
    }

    nav ul.mobile-menu-true {
        margin: 0;
        display: flex;
        background: rgba(0, 0, 0, 0.9);
        width: 50vw;
        position: absolute;
        top: 65px;
        right: 0px;
        align-items: flex-end;
        text-align: center;
        padding: 10px 0;
        padding-right: 10px;
    }
    button.live-scoring-teams-button {
        margin-right: 0 !important;
    }
    .game-page-header {
        flex-direction: row !important;
    }
    .team-scoring-list li button {
        padding: 30px !important;
        text-align: center;
        align-items: center;
    }

    nav ul li ul {
        position: relative;
        padding-right: 5px;
        top: 0;
    }
    footer {
        flex-direction: column;
        margin-top: 40px;
    }
    main.main-content {
        padding: 10px 0 0 0;
        margin: 0;
        max-height: 100vh;
        overflow-x: hidden;
    }
    main > section {
        max-width: 90vw;
        width: 90vw;
        padding: 10px;
    }
    section.game-page {
        margin-top: 40px !important;
    }
    .season-page {
        width: 100%;
    }
    table th,
    table td,
    table a {
        font-size: 0.6rem;
    }
    form {
        padding: 20px 0;
    }
    input {
        font-size: 0.7rem;
        width: 80%;
    }
    .standings-wrapper > table {
        margin-right: 0;
        width: 100% !important;
    }
    header nav img {
        height: 50px;
    }
    header.main-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100vw;
        z-index: 1000;
    }
    header.team-page-header {
        margin-bottom: 0 !important;
    }
    div.team-page-season-header {
        margin-right: 0;
    }
    div.team-page-season-header.active-season-true {
        max-width: 100vw;
    }
    .championship-team img,
    .final-scoreboard img {
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .standings-wrapper > div {
        width: 100%;
    }
    .standings-wrapper > div table {
        width: 100%;
    }

    .standings-page h1 {
        font-size: 1.75rem;
    }
    .scores-enter-page section input {
        width: 100%;
    }

    .game-body-left,
    .game-body-right {
        order: 2;
        flex: 1;
    }
    .game-body {
        flex-direction: column !important;
    }
    .category-badges {
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center !important;
        align-items: center;
    }
    .category-badges li {
        margin: 10px 5% !important;
        max-width: 150px !important;
    }
    header.header.gray.dark.show-true {
        width: 100% !important;
        margin: 0 !important;
    }

    nav.header__nav {
        padding: 0 !important;
    }

    a.header__nav__link {
        flex: unset !important;
        padding-right: 5px !important;
    }

    span.header__nav__title {
        display: none !important;
    }

    img.header__nav__logo {
        height: 50px !important;
        margin: 0 10px 0 15px !important;
    }

    .team-search {
        width: 80vw !important;
    }

    input#team-search {
        width: 80vw !important;
        padding: 5px 0 !important;
        margin: 5px 10px 5px 0 !important;
    }
    .header__search__results {
        left: -20px !important;
    }
    .modal-main .header__search__results {
        left: 0 !important;
    }

    .modal-content {
        max-width: 100vw !important;
        max-height: 90vh !important;
        /* z-index: 10000000; */
    }

    .modal {
        z-index: 1000 !important;
    }

    .modal.small-header main.modal-main {
        margin-top: -72px !important;
    }
    header.modal-header {
        flex-direction: row;
        background-color: transparent;
    }

    h2.modal-title {
        font-size: 1.25rem !important;
        top: 8px !important;
    }
    .home {
        flex-direction: column !important;
        margin-top: 50px !important;
    }

    .home__upcoming-games-list {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: space-evenly !important;
    }

    .home__standings {
        margin-top: 50px !important;
    }

    .home__recent-games {
        margin-top: 50px !important;
    }

    .home__recent-games-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .home__recent-game {
        width: 40vw !important;
        margin: 2vw 1vw;
        border: 1px solid gray;
    }
    .home__recent-game-pagination {
        flex-direction: row;
        /* margin-bottom: 10px; */
    }

    .home__recent-game-pagination-details {
        margin-bottom: 30px;
    }
    ul.teams-page-scores-list {
        flex-direction: row !important;
    }
    section.standings-page {
        margin-top: 50px !important;
    }
    .login_left {
        display: none;
    }
    .login_right {
        margin-left: 0 !important;
    }

    .login_right h2 {
        font-size: 2rem !important;
    }
    ul.header__nav__links {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 0;
        right: 0;
    }

    ul.header__nav__links a {
        font-size: 2rem;
    }

    footer.footer.mobile {
        display: none;
    }
    .mobile .home > div {
        padding-bottom: 20px;
    }
    .home__mobile-footer {
        display: flex !important;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        max-height: 100vh !important;
        background-color: transparent;
        z-index: 100;
        justify-content: flex-end;
    }

    ul.home__mobile-footer-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: black;
    }

    li.home__mobile-footer-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 5px 0 0;
        width: 20vw;
        box-sizing: border-box;
    }

    li.home__mobile-footer-item svg {
        height: 1.7em;
        width: 1.7em;
    }
    li.home__mobile-footer-item.current {
        background-color: #9b9bfe;
    }
    ul.home__recent-game-standings {
        /* flex-wrap: wrap; */
        /* display: flex; */
    }

    .home__recent-games-list {
        flex-wrap: wrap;
    }

    .home__recent-game {
        width: 90vw !important;
    }

    li.home__recent-game-standing {
        flex-direction: row;
        align-items: center;
    }

    .mobile .home > div {
        margin-top: 0 !important;
    }
    .home__recent-games-list {
    }

    .mobile ul.header__nav__links {
        display: none;
    }
    .home__mobile-footer.gray {
        margin: 0;
    }
    .mobile .host-sales-page {
        margin-top: 30px;
    }

    .host-sales-features {
        align-items: center;
    }

    .host-sales-page-header h1 {
        line-height: 3rem;
    }

    .host-sales-page .feature {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .feature .icon {
        margin-bottom: 0;
    }

    .feature .icon svg {
        font-size: 3rem;
        line-height: 3rem;
    }
    .schedule-page-map {
        width: 100vw !important;
        padding-top: 2vh !important;
    }

    .schedule-page-map > div {
        width: 80vw !important;
        margin: 0 auto;
        height: 50vh !important;
    }
    .schedule-page-calendar {
        width: 95vw !important;
        margin: 0 auto;
    }

    .schedule-page-wrapper button.calendar__nav-btn {
        font-size: 1rem;
        padding: 11px 10px 0;
        line-height: 1.7rem;
    }
    .calendar--monthly li.calendar__week-bottom-item {
        /* width: calc(100%/7); */
        /* width: 100%; */
        overflow: hidden;
    }
    .calendar-event-game {
        background-color: #ccc;
        border-radius: 5px;
        padding: 5px;
        font-size: 0.8rem;
        margin: 2px 0;
        text-align: left;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .calendar--monthly .calendar__week-bottom {
        display: flex;
        flex-direction: column;
    }

    .calendar--monthly .calendar__week-bottom-item {
        width: 100%;
        min-width: auto;
        margin: 0 0 10px 0;
    }
    .calendar__header button {
        padding: 10px 15px;
        font-size: 1rem;
        background-color: #007bff;
        color: #fff;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    .calendar-event-game__venue {
        font-size: 1rem;
    }

    .calendar-event-game__host {
        font-size: 0.8rem;
    }
    .calendar-event-game {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        min-height: 170px;
        position: relative;
    }
    .calendar__week-bottom-item ul {
        overflow: hidden;
    }

    .calendar__week-bottom-item.show-all ul {
        max-height: none;
    }

    h1.page-title {
        margin-top: 8vh;
    }
    .team-header {
        flex-direction: row;
        flex-wrap: nowrap;
        padding-left: 10px;
    }
    .team-header span {
        display: none;
    }
    button.done-button {
        display: none;
    }
    button.done-button.mobile {
        display: inline !important;
    }
    button.time-to-score-button {
        font-size: 1.5rem !important;
    }
    span.current-question-preview.black-text {
        padding: 0 10px;
    }
    .live-scoring .category-selection-name {
        font-size: 1rem !important;
        line-height: 1rem !important;
    }

    .live-scoring .slide.category-selection-slide {
        padding: 0px 10px !important;
    }
    .slide-buttons {
        bottom: 35.3vh !important;
    }
    section.scores-enter-page.host-dashboard {
        padding-top: 70px;
    }

    header.calendar__header.black-text {
        flex-direction: column;
    }

    .calendar.calendar--host-dashboard h2.calendar__title {
        margin: 10px 0 0;
    }

    ul.calendar__nav {
        margin: 0;
    }

    p.host-venmo {
        flex-direction: row;
    }

    span.venmo-label {
        font-size: 1.5rem !important;
    }

    p.host-venmo input[type="text"] {
        font-size: 1rem !important;
    }

    ul.calendar__week-top {
        flex-direction: row;
        margin-top: 50px;
    }

    span.calendar__week-top-day {
        font-size: 1rem !important;
    }

    span.calendar__week-top-date {
        font-size: 1.25rem !important;
    }

    .calendar.calendar--host-dashboard
        .calendar__week-bottom-template-add--game {
        font-size: 0.75rem !important;
    }

    .calendar-event-game-view-host-dashboard span.calendar-event-game__venue {
        font-size: 1rem !important;
        padding: 10px 0 !important;
        text-wrap: wrap;
        text-align: center;
        line-height: 1rem;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    span.round-item-subcategory {
        font-size: 0.75rem !important;
    }
    span.next-prev-slide-title {
        font-size: 1rem !important;
    }
    button.add-venue-btn {
        top: -95px !important;
    }
    span.calendar-event-game__teams {
        text-wrap: wrap;
    }
    section.admin-page {
        padding-top: 50px;
    }
    header.game-page-header {
        flex-direction: column !important;
        display: flex !important;
    }

    .game-page-header-left {
        width: 100% !important;
        order: 1 !important;
    }

    h2.game-venue {
        flex-direction: row !important;
        justify-content: space-between !important;
        margin: 0 !important;
    }

    span.icons {
        flex: 1 !important;
        justify-content: flex-end !important;
    }

    h3.game-host {
        text-align: left !important;
        margin: 0 0 10px 0 !important;
    }

    span.game-venue-label {
        font-size: 1.25rem !important;
    }

    .game-page-header-center {
        order: 3 !important;
    }

    .game-page-header-right {
        order: 2 !important;
        width: 100% !important;
        text-align: center !important;
        margin: 0 0 10px 0 !important;
    }
    .game-page-header-left {
        text-align: center;
    }

    h3.game-host {
        text-align: center !important;
    }

    .game-page-header-right {
        padding-right: 0 !important;
    }
    .modal header.game-page-header {
        padding-top: 40px !important;
    }

    .game-page-header-left {
        text-align: center !important;
    }

    .venue-page.page {
        margin-top: 70px !important;
    }

    .venue-page__top-ten-scoreboard h2 {
        font-size: 2rem;
    }

    h2.game-date {
        font-size: 1rem !important;
    }
    ul.category-badges {
        display: none !important;
    }
    section.game-page {
        margin-top: 70px !important;
    }
    .user-content {
        margin: 20px;
    }

    .mobile .home {
        overflow: scroll;
    }
    ul.user-page-teams--list {
        flex-direction: row !important;
    }
    li.user-page-teams--list-item {
        width: 100px !important;
        height: 100px !important;
        font-size: 1rem !important;
    }
    .modal.team-modal main.modal-main {
        margin-top: -50px !important;
    }
    /* .mobile > .home > div {
        display: none;
    } */
    .home__mobile-footer.gray.show-false {
        display: none !important;
    }
    section.game-page {
        flex-direction: row !important;
    }
    a.become-host-button {
        width: 90%;
        margin: 10px 5%;
        text-transform: uppercase;
    }
    button.join-team-as-member,
    button.leave-team-as-member,
    button.add-new-team,
    button.edit-team-display-name {
        top: 7px !important;
        right: 7px !important;
        font-size: 1rem !important;
        font-weight: 500;
        left: 7px;
        padding: 10px 15px 7px !important;
    }
    button.edit-team-display-name {
        left: 7px;
        top: 52px !important;
        right: 7px !important;
    }
    .game-team-modal .team-members {
        margin-top: 75px;
        background-color: lightgoldenrodyellow;
    }

    .modal .team-search {
        margin-top: 40px !important;
    }

    select.team-search__dropdown {
        width: 100%;
        font-size: 1.2rem;
    }
    .create-new-team {
        margin-top: 25px !important;
        width: 95% !important;
    }

    .create-new-team-buttons {
        flex-direction: row !important;
    }

    .create-new-team button {
        width: auto !important;
    }

    .create-new-team input {
        font-size: 1.2rem;
    }
    span.team-displayname {
        flex-direction: column;
        display: flex;
    }

    span.team-members {
        margin-top: -7px !important;
        font-size: 1rem !important;
    }

    .team-members svg {
        top: 3px !important;
        position: relative;
    }
    .team-members h2 {
        margin-top: 0;
    }
    button.game-credits-button.splash-bg {
        top: 175px !important;
        padding: 5px 15px;
        width: 100px;
    }
    button.user-logout {
        text-align: right !important;
        width: auto;
        margin-right: 0 !important;
    }
    span.game-credits-amount {
        font-size: 1.25rem !important;
    }
    span.game-credits-label {
        display: none !important;
    }
    .calendar.calendar--host-dashboard span.calendar-event-game__visibility {
        left: 5px;
        top: 0;
    }
    h2.live-game-modal-title {
        font-size: 7vw !important;
    }
    a.live-game-modal-button,
    a.live-game-modal-button:hover {
        font-size: 7vw !important;
        line-height: 7vw !important;
    }
    .answer-sheet-round {
        flex-basis: 47% !important;
    }
    .host-dashboard-header {
        flex-direction: column !important;
    }

    h2.host-dashboard-title {
        font-size: 1.75rem;
        line-height: 1rem;
        text-align: left;
        margin-bottom: 50px;
    }
    li.host-dashboard-button.game-credits-button {
        position: absolute;
        right: 0;
        top: 80px;
        flex-direction: column !important;
        align-items: unset !important;
    }
    ul.host-dashboard-buttons {
        margin-top: 30px !important;
        flex-direction: column !important;
    }
    li.host-dashboard-button {
        flex-direction: row !important;
        align-items: center !important;
    }

    span.label {
        width: 100px !important;
        text-align: left !important;
        padding-left: 20px !important;
    }

    span.icon {
        background-color: unset !important;
        height: 20px !important;
        width: 20px !important;
        margin-left: 40px !important;
    }
    li.host-dashboard-button.game-credits-button .icon {
        width: 80px !important;
        background-color: lightgreen !important;
        height: 45px !important;
        justify-content: center !important;
        margin-left: 0 !important;
        flex-direction: row !important;
    }

    span.game-credits {
        padding-left: 5px;
    }
    li.game-credits-button-desktop {
        display: none !important;
    }
    li.game-credits-button-mobile {
        display: flex !important;
    }
    svg.host-dashboard-hamburger {
        display: block !important;
    }

    ul.host-dashboard-buttons {
        position: absolute;
        right: -20px;
        top: 30px;
        z-index: 1000;
        background-color: #18183e;
        padding-bottom: 10px !important;
    }

    ul.host-dashboard-buttons.no-show {
        display: none;
    }

    ul.host-dashboard-buttons.show {
        display: flex;
    }

    span.save-venmo,
    span.cancel-venmo {
        font-size: 1rem !important;
    }

    p.host-venmo {
        margin-top: 30px;
    }

    svg.host-dashboard-hamburger {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1000;
        font-size: 2rem;
    }

    .game-credits-button span.label {
        text-align: center !important;
        padding-left: 0 !important;
        width: 80px !important;
    }
    .main-content > .home > .home__standings,
    .main-content > .home > .home__recent-games {
        display: none;
    }
    .main-content > .home.home-tab-recent > .home__recent-games {
        display: flex;
    }
    .main-content > .home.home-tab-standings > .home__standings {
        display: flex;
    }
    .live-scoring-header-buttons {
        flex-direction: row;
    }
    section.game-page {
        flex-direction: column !important;
    }
    .answer-sheet-round ul {
        flex-direction: row;
    }
    .nudeegg {
        left: 0;
    }
    .team-search.search-view-header {
        display: none;
    }

    nav.header__nav {
        justify-content: center !important;
    }
    div.venue-page__no-game p {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    div.venue-page {
        margin-top: 0;
    }

    div.venue-page__header-left h1 {
        text-align: center;
    }
    ul.venue-page__countdown-timer li.blinking-separator {
        display: none;
    }
    .venue-page__next-game h2 {
        margin-top: 0;
    }

    span.timer-number {
        font-size: 7rem !important;
        line-height: 7rem !important;
    }

    span.timer-label {
        line-height: 0rem;
        margin-top: -20px;
        margin-bottom: 20px;
    }

    div.venue-page__top-ten-scoreboard {
        margin-top: 30px;
        width: 100%;
        margin-bottom: 40px;
    }
    button.launch-todays-game-button,
    button.schedule-game-without-date {
        margin-right: 0 !important;
    }

    .host-intelligent-buttons {
        margin-bottom: 20px;
        border-bottom: 1px solid white;
        padding: 0 20px 10px;
    }

    .host-dashboard hr {
        display: none;
    }
    p.create-game-form-visiblity {
        flex-direction: row;
        align-items: center;
    }

    p.create-game-form-visiblity input {
        margin: 0 !important;
        width: 30px;
        margin-left: 10px !important;
    }
    .slide-dropdown {
        flex-direction: row;
        width: 94%;
    }
    .modal.game-team-modal .modal-content {
        width: 90vw !important;
        max-width: 90vw !important;
        height: 90vh !important;
        max-height: 90vh !important;
    }
    li.transaction {
        flex-direction: row;
    }
    .host-sales-page-header {
        flex-direction: column !important;
    }

    .host-sales-page-header-left {
        text-align: center !important;
    }

    .host-sales-page-header h1 {
        text-align: center !important;
        margin-top: 2vh !important;
    }

    .host-sales-page .price strong {
        font-size: 1.5rem !important;
    }

    p.price {
        text-align: center !important;
        font-size: 1.5rem !important;
        line-height: 1.7rem !important;
    }

    .become-a-host-now-button {
        padding: 0 20px 20px !important;
        font-size: 1.5rem !important;
    }

    .become-a-host-now-button span {
        position: relative !important;
        top: 0 !important;
        font-size: 1.5rem !important;
    }

    li.feature-item {
        margin: 40px auto !important;
        background: rgba(255, 255, 255, 0.2) !important;
        padding: 25px !important;
        border-radius: 25px !important;
    }
    .home__standings .season-countdown {
        left: 35vw;
        right: 35vw;
        top: 70px;
    }

    .home__standings .season-countdown-tooltip {
        left: 10vw;
        right: 10vw;
        top: 102px;
    }
    div.venue-page__top-ten-scoreboard .season-countdown {
        left: 20vw;
        right: 20vw;
        top: 45px;
    }

    div.venue-page__top-ten-scoreboard .season-countdown-tooltip {
        top: 80px;
        right: 5vw;
        left: 5vw;
    }
    p.call-to-action-text {
        width: 80% !important;
    }
    .player-features li.feature-item {
        background: rgba(0, 0, 0, 0.2) !important;
    }
    ul.tabs.black-text {
        flex-direction: row;
    }

    ul.tabs.black-text li {
        flex: 1;
    }
    .upcoming-games li,
    .past-games li {
        padding: 0 5px;
    }

    .agenda-view .tab-content {
        padding: 0;
    }

    span.date-header span {
        text-align: center;
        padding: 0;
        margin: 0;
    }

    ul.games-list > li {
        flex-direction: column;
        padding: 10px;
        position: relative;
    }

    .games-list span.game-status {
        display: none;
    }

    .games-list span.start-time {
        order: 2;
    }

    .games-list span.venue-details {
        order: 1;
    }

    .games-list span.game-buttons {
        order: 3;
        flex-direction: row;
    }

    ul.games-list > li span {
        width: 100%;
        text-align: center;
    }
    .agenda-view .game-buttons button,
    .agenda-view .game-buttons span.edit-game {
        padding: 0;
    }

    .agenda-view .game-buttons button.launch-todays-game-button span {
        display: none;
    }

    .agenda-view .game-buttons button.launch-todays-game-button {
        width: 100%;
    }

    .agenda-view .upcoming-games .game-buttons a.game-page-link {
        display: none;
    }

    .agenda-view .past-games .game-buttons button.launch-todays-game-button {
        display: none;
    }

    .agenda-view .game-buttons span.edit-game {
        background: none;
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
    }
    span.date-header {
        flex-direction: row;
        align-items: center;
        background: #ccc;
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 10px;
        margin-top: 0 !important;
    }
    ul.games-list {
        width: 100%;
    }
    a.game-page-link {
        width: 100%;
    }

    a.game-page-link button {
        width: 100%;
        line-height: 2rem;
    }
}
