@media (max-width: 1450px) {
    li.team-score-page-score {
        /* width: 100%; */
    }
}

@media (max-width: 1024px) {
    .team-page-header-center {
        margin-bottom: 0;
    }
    span.team-name-team-text {
        font-size: 1.5rem;
        margin-top: 0;
        margin-left: 0;
        max-width: 90%;
    }

    button.share-button svg,
    button.full-screen-button svg,
    button.edit-team-button svg {
        fill: white;
        width: 1.5rem;
        height: 1.5rem;
    }

    span.current-rank {
        font-size: 1rem;
    }

    .team-page-header-rank {
        /* padding: 20px; */
    }

    span.total-teams {
        font-size: 0.5rem !important;
    }

    span.total-teams a {
        font-size: 0.7rem;
        line-height: 0.75rem;
        margin-top: 6px;
    }

    button.share-button {
        top: -20px;
        background-color: transparent;
        border: none;
        padding: 0;
        right: 0;
    }

    svg.share-button-icon {
        width: 2rem;
        height: 2rem;
    }

    .team-page-content {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .team-page-content .team-page-content-section {
        width: 99%;
    }
    ul.medal-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    li.medal {
        flex: 1 1;
        margin: 10px;
        flex-basis: 45%;
        margin: 10px 0 !important;
        overflow: hidden;
    }
    li.badge {
        max-width: 150px;
        max-height: 150px;
        flex: 1 1;
        min-width: 150px;
        min-height: 150px;
    }

    .badge-back span {
        font-size: 0.7rem;
    }
    a.prev-team-side,
    a.next-team-side {
        display: none;
    }
    .team-page-clickable-header {
        cursor: pointer;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
    .team-page-clickable-header span {
        display: inline-block;
    }
    .team-page-clickable-header span svg {
        color: black;
        fill: black;
        width: 1.5rem;
        height: 1.5rem;
    }
    span.medal-icon {
        margin-right: 0px !important;
    }
    main.main-content.dark.mobile.main-team {
        padding-top: 70px;
    }

    button.share-button,
    button.full-screen-button,
    button.edit-team-button {
        margin-left: 0;
        margin-right: 20px;
    }
}

@media (max-width: 450px) {
    ul.badge-list {
        flex-direction: column;
    }
    li.badge {
        margin: 10px 0;
    }
}
@media (max-width: 400px) {
    span.team-name-team-text {
        width: 180px;
        text-overflow: ellipsis;
        height: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-wrap: pretty;
    }
}
