/* HostDashboard.styl */
.host-dashboard {
    position: relative;
}

.help-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.help {
    position: fixed;
    top: 10vh;
    left: 10vw;
    width: 80vw;
    max-height: 80vh;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    z-index: 1001;
    overflow-y: auto;
}
.close {
    top: 10px !important;
    right: 10px !important;
}
.help > * {
    color: black;
}
.help h1 {
    text-align: left;
    color: #333;
    margin: 0 0 40px;
    font-size: 2rem;
}

.host-training-video {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.host-training-video iframe {
    flex: 1 1;
    height: 25vh;
    background-color: black;
    border: 1px solid black;
    border-radius: 5px;
}

.host-training-video {
    border-top: 1px solid #ccc;
    padding: 20px;
    margin: 20px 0;
}

.info {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
}

.info > * {
    color: #333;
    /* margin: 0; */
    flex: 1 1;
}

.info h2 {
    margin-top: 0;
}

.host-training-video > * {
    flex: 1;
}

.info .time {
    font-weight: 700;
    font-size: 0.75rem;
    color: #333;
}

.host-dashboard .modal * {
    color: #333;
}

.create-game-form .live-scoring-list li {
    font-size: 1.3rem;
    padding: 5px;
}

.create-game-form li.live-scoring-venue-day {
    background: lightgoldenrodyellow;
    font-weight: 700;
}

.create-game-form {
    padding: 0 20px;
}
p.host-venmo {
    display: flex;
    align-items: center;
    justify-content: center;
}

span.venmo-label {
    font-size: 2rem;
    margin-right: 10px;
    margin-top: -15px;
}

p.host-venmo input[type="text"] {
    color: black;
    font-size: 1.5rem;
    width: 150px;
}

span.save-venmo,
span.cancel-venmo {
    background-color: lightgreen;
    font-size: 2rem;
    color: black;
    padding: 5px 10px;
    line-height: 2rem;
    margin-top: -15px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
}
span.cancel-venmo {
    background-color: lightcoral;
}
.calendar-event-game-view-host-dashboard {
    cursor: pointer;
    position: relative;
}
.calendar-event-game-view-host-dashboard span.calendar-event-game__venue {
    font-size: 2rem;
    padding: 20px;
}
.calendar-event-game.calendar-event-game-status-finalized {
    background-color: lightgreen;
}
.calendar.calendar--host-dashboard .calendar__week-bottom-template-add--game {
    background-color: green !important;
    color: white !important;
}
li.calendar-event-game.missing-template {
    background-color: gray;
    cursor: not-allowed;
}

.purchase-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.purchase-options button {
    padding: 10px;
    font-size: 1.2rem;
    flex-basis: 25%;
    cursor: pointer;
}
button.game-credits-button {
}
.calendar.calendar--host-dashboard
    .calendar__week-bottom-template-add--game:disabled {
    background-color: gray !important;
    text-decoration: line-through;
}
p.create-game-form-visiblity {
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
}

p.create-game-form-visiblity input {
    margin-left: 20px;
}

p.create-game-form-visibility-management {
    width: 80%;
    max-width: 500px;
    margin: 10px auto;
    padding: 10px 0;
    font-size: 1.2rem;
    line-height: 1.3rem;
    color: red !important;
    font-weight: 500;
}
.calendar.calendar--host-dashboard span.calendar-event-game__visibility {
    color: red !important;
    position: absolute;
}
.live-scoring-list li.selected {
    border: 5px solid lightgreen;
}
p.selected-venue-time input {
    margin: 0 auto;
    font-size: 1.5rem;
}

p.selected-venue-time {
    margin: 20px 0;
}

button.create-game-btn {
    padding: 5px 15px;
    background-color: lightgreen;
    font-size: 1.5rem;
    border: 0;
    margin-bottom: 30px;
}

span.calendar-event-game__time {
    font-size: 1.2rem;
    margin-top: -30px;
}

.host-dashboard-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

ul.host-dashboard-buttons {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

li.host-dashboard-button {
    margin: 0 5px;
}
li.host-dashboard-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

li.host-dashboard-button.score-sheets-button .icon svg {
    fill: transparent !important;
    color: transparent;
    stroke: #666;
}

li.host-dashboard-button.score-sheets-button {
    align-items: center;
}

span.icon {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

span.icon > * {
    fill: #666;
    color: #666;
}

li.host-dashboard-button:hover span.icon {
    background-color: rgb(155 155 254);
}
li.host-dashboard-button:hover span.icon > * {
    fill: white;
    color: white;
}

span.label {
    font-size: 1rem;
}
li.host-dashboard-button.game-credits-button .icon {
    width: 80px;
    justify-content: space-evenly;
    border-radius: 5px;
    background-color: lightgreen;
}

li.host-dashboard-button.venmo-button .icon svg {
    fill: #008cff;
    color: #008cff;
}

li.host-dashboard-button.venmo-button:hover .icon svg {
    fill: white;
    color: white;
}
li.host-dashboard-button.venmo-button:hover .icon {
    background-color: #008cff;
}
li.host-dashboard-button.logout-button .icon {
    background-color: red;
}

li.host-dashboard-button.logout-button .icon svg {
    fill: white;
}
li.host-dashboard-button.logout-button:hover .icon {
    background-color: white;
}

li.host-dashboard-button.logout-button:hover .icon svg {
    fill: red;
}
ul.transactions-list {
    list-style: none;
    padding: 0;
    margin-top: 0;
}

li.transaction {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

li.transaction span {
    flex: 1;
}
span.transaction-date {
    display: flex;
    flex-direction: column;
}
span.transaction-time {
    font-size: 1rem;
    line-height: 1rem;
}
.transaction-credits.transaction-credit-type-purchase {
    color: green !important;
    font-weight: 700;
}

.transaction-credits.transaction-credit-type-game {
    color: red;
    font-weight: 400;
}
span.transaction-credits.transaction-credit-type-game.game-positive {
    color: green;
    font-weight: bold;
}

span.transaction-type {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.transaction-type a {
    background-color: rgb(155 155 254);
    color: white !important;
    padding: 5px 15px;
    border-radius: 10px;
}
span.current-game-credits {
    background: lightgreen;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px 15px;
    border-radius: 5px;
    align-items: center;
    min-width: 100px;
    font-size: 1.25rem;
}
.manage-game-credits-buttons {
    position: absolute;
    top: -33px;
    right: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
button.transfer-credits-button {
    background-color: orange;
    border: 0;
    padding: 5px 15px;
    font-size: 1.25rem;
    margin-left: 10px;
}
.transfer-credits {
    width: 90%;
    margin: 0 auto;
}

.transfer-credits > p {
    font-size: 1.2rem;
    line-height: 1.2rem;
}
form.transfer-credits-form {
    margin: 0 auto;
}

form.transfer-credits-form input {
    width: 100%;
    text-align: center;
    border: 2px solid gray;
}

form.transfer-credits-form input[type="submit"] {
    background-color: lightgreen;
    border: 0;
    min-width: 25%;
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
}
form.transfer-credits-form input[type="submit"]:disabled {
    background-color: gray;
    color: lightgray;
}
span.transaction-type-status {
    font-size: 1rem;
    margin-top: -5px;
}
span.transaction-credits.transaction-credit-type-transfer.negative {
    color: red;
}
span.transaction-credits.transaction-credit-type-transfer.positive {
    color: green;
}
span.transaction-actions button {
    padding: 5px;
    color: white !important;
    background-color: red;
    border: 0;
    margin-right: 5px;
}
li.host-dashboard-button.game-credits-button.pending-true .icon {
    background-color: orange;
}

li.host-dashboard-button.game-credits-button.pending-true .icon > * {
    fill: black;
    color: black;
}
button.accept-transaction {
    background-color: green !important;
}
ul.credits-controls-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
}

ul.credits-controls-list li {
    flex: 1;
    margin: 0 2px;
}
.credits-controls-list.add button {
    background-color: lightgreen;
}

.credits-controls-list button {
    border: 0;
    background-color: lightcoral;
}
li.transaction:nth-child(even) {
    background-color: lightgoldenrodyellow;
    border: 2px solid gray;
    border-right: 0;
    border-left: 0;
}
span.transaction-credits.transaction-credit-type-admin.admin-negative {
    color: red;
}

span.transaction-credits.transaction-credit-type-admin.admin-positive {
    color: green;
    font-weight: 700;
}
.purchase-credits-payment-form {
    display: grid;
    justify-content: center;
    align-content: center;
    position: relative;
}
p.purchase-title {
    font-size: 1.7rem;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
}

button#rswp-card-button {
    background-color: lightgreen;
    font-size: 1.2rem;
    margin-top: 0px;
}
span.purchasing-blocker {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    color: white !important;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3rem;
}
li.game-credits-button-mobile {
    display: none;
}
svg.host-dashboard-hamburger {
    display: none;
}
span.create-game-form-date {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

span.create-game-form-date * {
    font-size: 1.3rem;
    margin-bottom: 0;
}

.create-game-form-date input#date {
    flex: 1;
    margin-left: 10px;
    text-align: center;
}
.host-intelligent-buttons {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
button.launch-todays-game-button,
button.schedule-game-without-date {
    background-color: gold;
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    padding: 5px 15px;
    margin-right: 20px;
    color: black;
}

button.schedule-game-without-date {
    background-color: green;
}

button.launch-todays-game-button svg,
button.schedule-game-without-date svg {
    font-size: 2rem;
    padding-right: 10px;
    fill: black;
}

button.schedule-game-without-date svg {
    font-size: 1.75rem;
    fill: white;
}

button.launch-todays-game-button span,
button.schedule-game-without-date span {
    font-size: 1rem;
    line-height: 1rem;
}
li.calendar-event-game.game-today {
    background-color: gold;
}
span.calendar-event-game__edit {
    position: absolute;
    right: 5px;
    top: 5px;
}
span.calendar-event-game__edit.enabled-false svg {
    fill: gray !important;
    cursor: not-allowed;
}
p.delete-venue-wrapper {
    margin: 0;
}

button.delete-venue-btn {
    margin: 0;
    background-color: red;
    border: 0;
    color: white !important;
}
.header-host-dashboard .make-money {
    display: none !important;
}
button.schedule-game-without-date:disabled {
    background-color: gray;
}

button.schedule-game-without-date:disabled span {
    text-decoration: line-through;
}
