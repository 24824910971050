/* media query for lower than 1024px */
@media (min-width: 1023px) {
    .controls-panel.hide {
        display: block;
    }
}

@media (max-width: 1023px) {
    .controls-panel.hide {
        display: none;
    }
    .day-button {
        width: 100% !important;
        margin-bottom: 1vh;
    }
    .live-scoring header,
    .live-scoring footer {
        flex-direction: row;
        font-size: 1rem;
    }
    .live-scoring-list li,
    .scoring-round ul {
        flex-direction: row;
        padding: 10px 3vw;
        align-items: center;
    }
    .scoring-round li h3,
    .live-scoring header h2,
    .live-scoring footer h2,
    .live-scoring header h2 span,
    .live-scoring footer h2 span {
        font-size: 1rem !important;
        line-height: 1.25rem;
    }

    .live-scoring header span.live-scoring-venue-name {
        font-size: 1rem !important;
        line-height: 1rem;
    }

    .close-game,
    button.live-scoring-teams-button,
    button.scoreboard-button,
    button.finalize-score {
        font-size: 0.75rem;
    }
    .live-scoring-list.scoring-round li ul li button {
        margin-left: 1vw;
        padding: 5px 4vw;
    }

    button.finalize-score-missing,
    button.finalize-score {
        margin-right: 0;
        padding: 5px 15px;
        font-size: 0.75rem;
        text-wrap: pretty;
        word-break: break-word;
        width: 140px;
    }
    button.finalize-score {
        width: auto;
        padding: 1rem;
    }
    button.live-scoring-teams-button {
        padding: 5px 15px;
    }
    .team-display span.team-name {
        font-size: 0.8rem;
    }

    span.display-name {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1rem;
    }
    .team-scoring-team-name .team-name {
        font-size: 0.8rem;
    }
    .team-scoring-view .team-scoring-team-name span.display-name {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-top: 15px;
    }
    .live-scoring-main-left {
        width: 100vw;
    }
    .live-scoring footer {
        display: block;
    }
    span.close-game {
        padding: 10px 20px;
    }
    .next-slide-preview-text * {
        font-size: 1.5rem;
    }
    .slide-buttons svg {
        max-width: unset;
        font-size: unset;
    }
    .live-scoring header h2 span.live-scoring-header-date-time {
        font-size: 0.8rem !important;
        line-height: 1rem;
    }

    .live-scoring header h2 span.live-scoring-header-third-line,
    .live-scoring header h2 span.live-scoring-header-third-line span {
        font-size: 0.8rem !important;
        line-height: 0.8rem;
    }
    .team-scoring-view li.live-scoring-go-back {
        padding: 10px;
    }
    span.team-players {
        flex-direction: row;
    }

    span.remove-team {
        font-size: 1rem;
        line-height: 1rem;
    }

    button.clear-search {
        right: 80px;
    }
}
