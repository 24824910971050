li.home__mobile-footer-item svg,
span.home__mobile-footer-item-label {
    color: white;
    fill: white;
}
.home__mobile-footer-item.disabled svg {
    fill: gray;
}
span.home__mobile-footer-item-label {
}

.home__mobile-footer-content {
    background-color: black;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
}

.home__mobile-footer-content * {
    color: white !important;
}
li.home__mobile-footer-item.live > * {
    fill: gold !important;
    color: gold !important;
}
