@media (max-width: 1024px) {
    main.split {
        flex-direction: row;
    }
    footer.slidefooter {
        flex-direction: row;
    }
    section.game-page.presentation-slideshow {
        margin-top: 0 !important;
    }
}
